import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'antd';
import cssIcon from '../Assets/TechStack/image50.png';
import testingIcon from '../Assets/TechStack/image51.png'
import mernIcon from '../Assets/TechStack/image52.png'
import rubyIcon from '../Assets/TechStack/image53.png'
import DevopsIcon from '../Assets/TechStack/image54.png'
import shopifyIcon from '../Assets/TechStack/image55.png'
import meanIcon from '../Assets/TechStack/image56.png'
import androidIcon from '../Assets/TechStack/image58.png'
import angularIcon from '../Assets/TechStack/image59.png'
import iosIcon from '../Assets/TechStack/image60.png'
import awsIcon from '../Assets/TechStack/image61.png'
import azureIcon from '../Assets/TechStack/image62.png'
import flutterIcon from '../Assets/TechStack/image65.png'
import goLangIcon from '../Assets/TechStack/image66.png'
import javaIcon from '../Assets/TechStack/image67.png'
import magentoIcon from '../Assets/TechStack/image68.png'
import nodeIcon from '../Assets/TechStack/image69.png'
import phpIcon from '../Assets/TechStack/image70.png'
import pythonIcon from '../Assets/TechStack/image71.png'
import reactIcon from '../Assets/TechStack/image72.png'
import swiftIcon from '../Assets/TechStack/image73.png'
import vueJsIcon from '../Assets/TechStack/image74.png'
import web3Icon from '../Assets/TechStack/image75.png'
import htmlIcon from '../Assets/TechStack/image76.png'
import jenkinsIcon from '../Assets/TechStack/image77.png'
import laravelIcon from '../Assets/TechStack/image78.png'
import styles from './Styles/skills.module.css';

const Skills = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const topIcons = [
        { image: cssIcon, tag: "CSS" },
        { image: testingIcon, tag: "Testing" },
        { image: mernIcon, tag: "Mern" },
        { image: rubyIcon, tag: "Ruby" },
        { image: DevopsIcon, tag: "Devops" },
        { image: shopifyIcon, tag: "Shopify" },
        { image: meanIcon, tag: "Mean" },
        { image: androidIcon, tag: "Android" },
        { image: angularIcon, tag: "Angular" },
        { image: iosIcon, tag: "iOS" },
        { image: awsIcon, tag: "AWS" },
        { image: azureIcon, tag: "Azure" }
    ];
    const midIcons = [
        { image: flutterIcon, tag: "Flutter" },
        { image: goLangIcon, tag: "Golang" },
    ]
    const bottomIcons = [
        { image: javaIcon, tag: "Java" },
        { image: magentoIcon, tag: "Magento" },
        { image: nodeIcon, tag: "NodeJS" },
        { image: phpIcon, tag: "PHP" },
        { image: pythonIcon, tag: "Python" },
        { image: reactIcon, tag: "React" },
        { image: swiftIcon, tag: "Swift" },
        { image: vueJsIcon, tag: "VueJS" },
        { image: web3Icon, tag: "Web3" },
        { image: htmlIcon, tag: "HTML" },
        { image: jenkinsIcon, tag: "Jenkins" },
        { image: laravelIcon, tag: "Laravel" }
    ];
    return !isMobile ? <>
        <div style={{overflowX: 'hidden',margin:"0 40px" }}>
            <Row style={{alignItems:"center", flexWrap: 'wrap' }} gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },16]}>
                {topIcons?.map(icon => (
                    <Col md={4} lg={2} key={icon?.tag}>
                        <div className={styles.skillIcon}>
                            <img style={{ width: "25px" }} src={icon?.image} alt={icon?.tag} />
                            <div style={{ fontSize: "0.7em" }}>{icon?.tag}</div>
                        </div>
                    </Col>
                ))}
            </Row>
            <Row style={{alignItems:"center", flexWrap: 'wrap' }} gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },16]}>
                <Col md={4} lg={2}>
                    <div className={styles.skillIcon}>
                        <img style={{ width: "25px" }} src={midIcons[0]?.image} alt={midIcons[0]?.tag} />
                        <div style={{ fontSize: "0.7em" }}>{midIcons[0]?.tag}</div>
                    </div>
                </Col>
                <Col md={16} lg={20}>
                    <p style={{fontSize:"1.3em",fontFamily:"Poppins,sans-serif"}}>A pool of 1000+ vetted and certified developers in 40+ skillstacks like Node.js, Python, Testing/QA, Ruby, React, SQL, VueJS, Java and more.</p>
                </Col>
                <Col md={4} lg={2} >
                    <div className={styles.skillIcon}>
                        <img style={{ width: "25px" }} src={midIcons[1]?.image} alt={midIcons[0]?.tag} />
                        <div style={{ fontSize: "0.7em" }}>{midIcons[1]?.tag}</div>
                    </div>
                </Col>
            </Row>
            <Row style={{alignItems:"center", flexWrap: 'wrap' }} gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },16]}>
                {bottomIcons?.map(icon => (
                    <Col md={4} lg={2} key={icon?.tag}>
                        <div className={styles.skillIcon}>
                            <img style={{ width: "25px" }} src={icon?.image} alt={icon?.tag} />
                            <div style={{ fontSize: "0.7em" }}>{icon?.tag}</div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
        </> : 
        <div style={{margin:"30px"}}>
        <p style={{fontSize:"1.2em",fontFamily:"Poppins,sans-serif"}}>A pool of 1000+ vetted and certified developers in 40+ skillstacks like Node.js, Python, Testing/QA, Ruby, React, SQL, VueJS, Java and more.</p>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },16]}>
            {topIcons?.map(icon => (
                <Col xs={6} sm={4} key={icon?.tag}>
                <div className={styles.skillIcon}>
                    <img style={{ width: "25px" }} src={icon?.image} alt={icon?.tag} />
                    <div style={{ fontSize: "0.7em" }}>{icon?.tag}</div>
                </div>
            </Col>
            ))}
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },16]}>
            {bottomIcons?.map(icon => (
                <Col xs={6} sm={4} key={icon?.tag}>
                <div className={styles.skillIcon}>
                    <img style={{ width: "25px" }} src={icon?.image} alt={icon?.tag} />
                    <div style={{ fontSize: "0.7em" }}>{icon?.tag}</div>
                </div>
            </Col>
            ))}
        </Row>
        </div>
    
}

export {Skills};

import React from "react";
import { useMediaQuery } from "react-responsive";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import HorizontalCard from "./Components/HorizontalCard";
import DevelopmentCard from "./Components/DevelopmentCard";
import { ContactForm } from "../ContactForm/ContactForm";
import { Layout, Typography } from "antd";
import AppBanner from "./../Assets/AppDevelopment/AppdevelopmentBanner.png";
import frontend from "../Assets/WebDevelopment/frontend.png";
import backend from "../Assets/WebDevelopment/backend.png";
import database from "../Assets/WebDevelopment/database.png";
import infoMobile from '../Assets/infoMobile.png';
import info from '../Assets/info.png';
import Planning from '../Assets/WebDevelopment/Planning.png';
import Design from '../Assets/WebDevelopment/Design.png';
import Development from '../Assets/WebDevelopment/Development.png';
import Testing from '../Assets/WebDevelopment/Testing.png';
import Deployment from '../Assets/WebDevelopment/Deployment.png';
import Maintainance from '../Assets/WebDevelopment/Maintainance.png';
import styles from "./Styles/services.module.css";
const { Text } = Typography;
const { Content } = Layout;
const AppDevelopment = () => {
  const isMobile =useMediaQuery({maxWidth:576});

  const process =[
    {
      image:Planning,
      desc:"Identify a problem or a need in the market that your app can address.",
      name:"Idea & Conceptualization"
    },
    {
      image:Design,
      desc:"Create a list of features & functionalities your app will offer.",
      name:"Features & Functionality"
    },
    {
      image:Development,
      desc:"Create wireframes & mockups to visualize the layout and user interface.",
      name:"Wireframing & Prototyping"
    },
    {
      image:Testing,
      desc:"Develop the visual elements of the app, including graphics, color schemes, & overall theme.",
      name:"Design"
    },
    {
      image:Deployment,
      desc:"Choose a development approach (native, hybrid, or cross-platform)",
      name:"Development"
    },
    {
      image:Maintainance,
      desc:"Conduct various types of testing, including unit testing, integration testing & system testing.",
      name:"Testing"
    }
  ]
  const stages = [
    {
      image: frontend,
      desc: "Developers use specialized software tools, known as IDEs, to write, test, and debug their code. Examples include Xcode for iOS development and Android Studio for Android development.",
      title: "Integrated Development Environment(IDE)",
    },
    {
      image: backend,
      desc: "Designing an intuitive and visually appealing user interface is crucial for a positive user experience. This involves creating layouts, graphics, and other elements that users interact with.",
      title: "User Interface Design (UI)",
    },
    {
      image: database,
      desc: "Mobile apps often rely on back-end servers to store & retrieve data. Developers need to create the server-side logic & APIs (Application Programming Interfaces) to enable communication between the mobile app & the server.",
      title: "Backend Development",
    },
  ];
  return (
    <div style={{overflow:"hidden"}}>
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={AppBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Process of creating software applications <br /> & maintaining software applications
        </Text>
      </Content>
      <HorizontalCard process={process}/>
      <div style={{ margin: "30px 0" }}>
        <Text className={styles.heading}>Web app development</Text>
        <h3 className={styles.stage}>
          Stages we are following to build your web application
        </h3>
        <DevelopmentCard stages={stages} />
      </div>
      <Content style={{ margin: "30px 0" }}>
                    <p style={{ fontSize: "1.3em",fontFamily:"Poppins,sans-serif" }}>Here is our <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>End to End</span> services to take you next level</p>
                    <p style={{ fontSize: "1.1em",fontFamily:"Poppins,sans-serif" }}>Services we are doing to improve your business & agile work process</p>
                    {isMobile ? <img src={infoMobile} alt='Process' style={{ width: "70%" }} />: <img src={info} alt='Process' style={{ width: "90%" }} /> }
      </Content>
      <div style={{ margin: "50px 0" }}>
      <Skills />
      </div>
      <ContactForm />
      <p style={{ fontSize: "1.8em",fontFamily:"Poppins,sans-serif" }}>One year of excellence & success</p>
      <ExcellenceAndSuccessCards/>
      <Insights />
    </div>
  );
};

export { AppDevelopment };

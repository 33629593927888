import React from 'react';
import { useMediaQuery } from 'react-responsive';
import DealWithDataCard from './Components/DealWithDataCard';
import { ContactForm } from '../ContactForm/ContactForm';
import { PragmasoftData, weDealWithData } from '../Common/Components/PragmasoftData';
import { ExcellenceAndSuccessCards, Insights, Skills } from '../Insights';
import { Card, Layout, Space } from 'antd';
import info from '../Assets/info.png';
import infoMobile from '../Assets/infoMobile.png';
import company from '../Assets/company.png';
import styles from './Styles/scaleup.module.css';

const { Content } = Layout;

const ScaleUp = () => {
    const isMobile = useMediaQuery({ maxWidth: 576 });

    return (
        <Space direction='vertical'>
            <Content className={styles.imageStyle}>
                <img src={company} className={styles.image} alt="Scale up" />
                <div className={styles.textStyle}>
                    <div style={{ fontSize: "1.5em" }}>Scaleup your technical developments</div>
                    <p style={{ fontSize: "1.1em" }}>Our over skilled developers are willing to work for your business</p>
                </div>
            </Content>
            <Content style={{ margin: "30px" }}>
                <Content>
                    <p style={{ fontSize: "1.3em" }}>Here is our <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>End to End</span> services to take you next level</p>
                    <p style={{ fontSize: "1.1em" }}>Services we are doing to improve your business & agile work process</p>
                    {isMobile ? <img src={infoMobile} alt='Process' style={{ width: "70%" }} /> : <img src={info} alt='Process' style={{ width: "90%" }} />}
                </Content>
                <p className={styles.pragmasoftTitle}>Why PragmaSoft?</p>
                <p style={{ fontSize: "1.2em", fontWeight: "400" }}>Know what we do for your scaleup</p>
                <Content className={styles.allCards}>
                    {PragmasoftData?.map(data =>
                        <Card key={data?.desc} className={`${styles.pragmasoftCard}`} style={{ backgroundColor: `${data.colour}` }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div className={styles.cardIcon}>
                                    <img src={data?.image} alt='icon' />
                                </div>
                                <div>
                                    <p style={{ fontWeight: "600" }}>{data?.title}</p>
                                    <p style={{ fontWeight: "400" }}>{data?.desc}</p>
                                </div>
                            </div>
                        </Card>
                    )}
                </Content>
                <p className={styles.pragmasoftTitle}>We deal with</p>
                <div className={styles.allCards}>
                    {weDealWithData?.map(data => (
                        <DealWithDataCard key={data?.desc} data={data} />
                    ))}
                </div>
            </Content>
            <ContactForm />
            <p style={{ fontSize: "1.8em" }}>One year of excellence & success</p>
            <ExcellenceAndSuccessCards />
            <Skills />
            <Insights />
        </Space>
    )
}

export { ScaleUp };
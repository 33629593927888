import React from "react";
import { useMediaQuery } from "react-responsive";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import HorizontalCard from "./Components/HorizontalCard";
import DevelopmentCard from "./Components/DevelopmentCard";
import { ContactForm } from "../ContactForm/ContactForm";
import { Layout, Typography } from "antd";
import webBanner from "./../Assets/WebDevelopment/webdevelopBanner.png";
import frontend from "../Assets/WebDevelopment/frontend.png";
import backend from "../Assets/WebDevelopment/backend.png";
import database from "../Assets/WebDevelopment/database.png";
import infoMobile from '../Assets/infoMobile.png';
import info from '../Assets/info.png';
import Planning from '../Assets/WebDevelopment/Planning.png';
import Design from '../Assets/WebDevelopment/Design.png';
import Development from '../Assets/WebDevelopment/Development.png';
import Testing from '../Assets/WebDevelopment/Testing.png';
import Deployment from '../Assets/WebDevelopment/Deployment.png';
import Maintainance from '../Assets/WebDevelopment/Maintainance.png';
import styles from "./Styles/services.module.css";



const { Text } = Typography;
const { Content } = Layout;
const WebDvelopment = () => {
  const isMobile =useMediaQuery({maxWidth:576});

  const process =[
    {
      image:Planning,
      desc:"Define the purpose & goals of the web application.",
      name:"Planning and Analysis"
    },
    {
      image:Design,
      desc:"Create wireframes & mockups to visualize the layout and user interface.",
      name:"Design"
    },
    {
      image:Development,
      desc:"Write the code for the web application based on the design specifications.",
      name:"Development"
    },
    {
      image:Testing,
      desc:"Conduct various types of testing, including unit testing, integration testing & system testing.",
      name:"Testing"
    },
    {
      image:Deployment,
      desc:"Deploy the web application to a production environment.",
      name:"Deployment"
    },
    {
      image:Maintainance,
      desc:"Provide ongoing support to users & address any issues that arise.",
      name:"Maintainance & Updates"
    }
  ]
  const stages = [
    {
      image: frontend,
      desc: "It involves creating the UX & UI of the application that users interact with. It includes designing & implementing the visual elements of the application using HTML, CSS, and JavaScript.",
      title: "Front-end Development",
    },
    {
      image: backend,
      desc: "The back end is responsible for handling the server-side logic, databases, and application functionality that user doesn't directly interact with. Technologies such as server-side programming languages.",
      title: "Back-end Development",
    },
    {
      image: database,
      desc: "Web applications often require a database to store and retrieve data. Database management involves designing the database structure, handling data storage and retrieval, and ensuring data integrity.",
      title: "Database Management",
    },
  ];
  return (
    <div style={{overflow:"hidden"}}>
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={webBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Process of creating <br /> & maintaining software applications
        </Text>
      </Content>
      <HorizontalCard
        process={process}
      />
      <div style={{ margin: "30px" }}>
        <Text className={styles.heading}>Web app development</Text>
        <h3 className={styles.stage}>
          Stages we are following to build your web application
        </h3>
        <DevelopmentCard stages={stages} />
      </div>
      <Content style={{ margin: "30px" }}>
                    <p style={{ fontSize: "1.3em",fontFamily:"Poppins,sans-serif" }}>Here is our <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>End to End</span> services to take you next level</p>
                    <p style={{ fontSize: "1.1em",fontFamily:"Poppins,sans-serif" }}>Services we are doing to improve your business & agile work process</p>
                    {isMobile ? <img src={infoMobile} alt='Process' style={{ width: "70%" }} />: <img src={info} alt='Process' style={{ width: "90%" }} /> }
      </Content>
      <div style={{ margin: "40px" }}>
      <Skills />
      </div>
      <ContactForm />
      <p style={{ fontSize: "1.8em",fontFamily:"Poppins,sans-serif" }}>One year of excellence & success</p>
      <ExcellenceAndSuccessCards/>
      <Insights />
    </div>
  );
};

export { WebDvelopment };

import React from "react";
import { Col, Image, Row, Typography } from "antd";
import { PiCaretRightBold } from "react-icons/pi";
import BlogImage from "../Assets/Blog/banner.png";
import blog_1 from "../Assets/Blog/B1.png";
import blog_2 from "../Assets/Blog/B2.png";
import blog_3 from "../Assets/Blog/B3.png";
import blog_4 from "../Assets/Blog/B4.png";
import blog_5 from "../Assets/Blog/B5.png";
import blog_6 from "../Assets/Blog/B6.png";
import styles from "./Styles/blog.module.css";
const { Text, Title } = Typography;

const blogSectionDetails = [
  {
    title: "Generative AI - From Hype to Business Impact",
    descirption:
      "Generative AI has surged onto the global stage, disrupting traditional business practices and models. As leaders, innovators, and analysts, we are engaged in a race to effectively harness and apply generative AI tools. While we marvel at the…",
    image: blog_1,
    date: "November 8, 2023",
    author: "Pascal Bornet, Artificial Intelligence (AI) Expert",
  },
  {
    title:
      "Using AI and Large Language Models for Nuanced Localization in the BFS Sector: A Competitive Edge in a Fragmented Internet Landscape",
    descirption:
      "In the evermore competitive landscape of banking and financial services (BFS), a deep understanding of customers, regulations, and local cultures is essential. As the Internet offers global access to financial services, businesses seeking to…",
    image: blog_2,
    date: "November 8, 2023",
    author:
      "Antony Panteli, Head of CIO Advisory, LTIMindtree Consulting (EU & UK)",
  },
  {
    title: "Preparing for a New Breed of Customers: The Gen AI Natives",
    descirption:
      "The ability to predict the future is central to society. Today, we try to predict everything, from the outcome of the ball game this evening to the weather tomorrow morning, from the state of the stock market this month to the likely value of…",
    image: blog_3,
    date: "November 8, 2023",
    author: "Sudhir Chaturvedi, Whole-Time Director & President, Markets.",
  },
  {
    title: "Product Marketing: Assurance over Insurance",
    descirption:
      "Human tendency is to look for security & assurance in every aspect of our lives. Even when buying an item, we check the reviews, product quality, and durability. If you are about to buy a product that will be used for a long time, for example,…",
    image: blog_4,
    date: "November 7, 2023",
    author: "Yatin Parwatkar, Product Owner, Canvas Resilience by LTIMindtree",
  },
  {
    title: "How to Accelerate the Adoption of a Transparent and Adaptable AI?",
    descirption:
      "IntroductionChatGPT is an unprecedented gamechanger, especially in terms of breaking product lifecycle records and landing in the trough of disillusionment before we could realize its full potential. On the other hand, enterprises of all…",
    image: blog_5,
    date: "October 6, 2023",
    author:
      "Rakesh Suresh, Associate Principal -Collective Success, LTIMindtree",
  },
  {
    title: "Why Businesses Should Embrace Zero-Trust Model",
    descirption:
      "Zero-trust models are becoming increasingly popular as businesses seek ways to protect their data, applications, and networks from cyber threats. The proliferation of connected devices and applications moving to clouds has triggered the need…",
    image: blog_6,
    date: "October 5, 2023",
    author:
      "Dilip Panjwani, Global Head of the Cybersecurity Technology Office and CoE, MN systems",
  },
];

const Blog = () => {
  return (
    <div>
      <section className={styles.bannerImage}>
        <img src={BlogImage} alt="Support" className={styles.image} />
        <div className={styles.overlay}>
          <Text className={styles.text}>Blog</Text>
        </div>
      </section>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className={styles.blog_card_contatiner}
      >
        <Col xs={24} sm={18} md={18} lg={18}>
          <Row
            gutter={[
              16,
              {
                xs: 8,
                sm: 16,
                md: 20,
                lg: 32,
              },
            ]}
          >
            {blogSectionDetails?.map((blog) => (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                key={blog?.title}
                className={styles.blogDetailsColumn}
              >
                <div className={styles.blog_card}>
                  <Image preview={false} src={blog?.image} alt={blog?.title} />
                  <div className={styles.blog_texts}>
                    <Title className={styles.title}>{blog?.title}</Title>
                    <Text className={styles.description}>
                      {blog?.descirption}
                    </Text>
                    <div className={styles.date_author}>
                      <Text className={styles.date}>{blog?.date}</Text>
                      <Text className={styles.author}>By: {blog?.author}</Text>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6}>
          {/* right section */}
          <div className={styles.rightSection}>
            <div className={styles.services}>
              View by Services
              <span>
                <PiCaretRightBold />
              </span>
            </div>
            <div className={styles.industries}>
              View by Industries{" "}
              <span>
                <PiCaretRightBold />
              </span>
            </div>
            <div className={styles.allBlogs}>All blogs</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { Blog };

import React from "react";
import PhoneInput from "react-phone-input-2";
import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "../Styles/careers.module.css";

const ResumeForm = () => {
  const experienceOptions = [
    { value: "0", option: "Fresher" },
    { value: "1", option: "1 Year" },
    { value: "2", option: "2 Years" },
    { value: "3", option: "3 Years" },
    { value: "4", option: "4 Years" },
  ];
  return (
    <>
      <Form wrapperCol={24} layout="horizontal">
        <Row>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ offset: 1, span: 10 }}
            lg={{ offset: 2, span: 9 }}
          >
            <Form.Item>
              <Input
                placeholder="First Name*"
                style={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ span: 10 }}
            lg={{ span: 9 }}
          >
            <Form.Item>
              <Input
                placeholder="Last Name*"
                style={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ offset: 1, span: 10 }}
            lg={{ offset: 2, span: 9 }}
          >
            <Form.Item>
              <Input
                placeholder="Country*"
                style={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ span: 10 }}
            lg={{ span: 9 }}
          >
            <Form.Item name="phoneNumber" rules={[{ required: true }]}>
              <PhoneInput
                countryCodeEditable={false}
                country="in"
                inputStyle={{
                  width: "100%",
                  padding: "4px 11px 4px 48px",
                  border: "0.5px solid rgba(0, 0, 0, 0.50)",
                  height: "31.45px",
                  borderRadius: "6px",
                }}
                buttonStyle={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ offset: 1, span: 10 }}
            lg={{ offset: 2, span: 9 }}
          >
            <Form.Item>
              <Input
                placeholder="Email*"
                style={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ span: 10 }}
            lg={{ span: 9 }}
          >
            <Form.Item style={{ textAlign: "left" }}>
              <Select
                placeholder="Select Experience in years*"
                style={{
                  border: "0.5px solid rgba(0, 0, 0, 0.50)",
                  borderRadius: "6px",
                }}
              >
                {experienceOptions?.map((option) => (
                  <Select.Option key={option?.option} value={option?.value}>
                    {option?.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ offset: 1, span: 10 }}
            lg={{ offset: 2, span: 9 }}
          >
            <Form.Item
              name="upload"
              valuePropName="filelist"
              extra="Upload your Resume* (only .doc, .rtf, .pdf files)"
              style={{ borderRadius: "6px", textAlign: "left" }}
            >
              <div
                style={{
                  border: "0.5px solid rgba(0, 0, 0, 0.50)",
                  borderRadius: "6px",
                  background: "white",
                }}
              >
                <Upload name="logo" action="/upload.do" listType="picture">
                  <Button style={{ border: "none" }} icon={<UploadOutlined />}>
                    Click to upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </Col>
          <Col
            xs={{ offset: 2, span: 20 }}
            sm={{ offset: 2, span: 20 }}
            md={{ span: 10 }}
            lg={{ span: 9 }}
          >
            <Form.Item name="phoneNumber" rules={[{ required: true }]}>
              <PhoneInput
                countryCodeEditable={false}
                country="in"
                inputStyle={{
                  width: "100%",
                  padding: "4px 11px 4px 48px",
                  border: "0.5px solid rgba(0, 0, 0, 0.50)",
                  height: "31.45px",
                  borderRadius: "6px",
                }}
                buttonStyle={{ border: "0.5px solid rgba(0, 0, 0, 0.50)" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button className={styles.submitbtn}>Submit</Button>
      </Form>
    </>
  );
};

export default ResumeForm;

import React from "react";
import { Button, Col, Image, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import PARImage from "../../Assets/PAR.png";
import styles from "../Styles/dataDriven_questions_PAR.module.css";
const { Title } = Typography;

const PAR = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <section className={styles.bg_color_2} style={{ overflowX: "hidden" }}>
      <Row justify="center">
        <Title className={styles.drivenByDataTitle}>
          Passion. Action. Responsibility.
        </Title>
      </Row>
      <Row
        style={{ padding: "2rem 3rem 3.5rem 3rem" }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        justify="center"
      >
        <Col md={1} lg={1} order={1}></Col>
        <Col xs={24} sm={24} md={11} lg={11} order={isMobile ? 2 : 2}>
          <Row style={{ height: "100%" }} align="middle">
            <Col span={24} className={styles.drivenByDataText}>
              <p>
                We meet our commitment to communities around the world with
                innovations in STEM & important actions & results in our
                environmental impact, ethical practices & sustainable
                procurement. EcoVadis rates informatica above average in our
                industry
              </p>
              <Button className={styles.btn}>See social responsibility</Button>
            </Col>
          </Row>
        </Col>
        <Col md={1} lg={1} order={3}></Col>
        <Col xs={24} sm={24} md={10} lg={10} order={isMobile ? 1 : 4}>
          <Image src={PARImage} preview={false} style={{ width: "100%" }} />
        </Col>
        <Col md={1} lg={1} order={5}></Col>
      </Row>
    </section>
  );
};

export default PAR;

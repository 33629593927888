const menuOptions = [
  {
    label: "Home",
    key: ""
  },
  {
    label: "Company",
    key: "company",
    children: [
      // {
      //   label: "About Us",
      //   key: "aboutus"
      // },
      // {
      //   label: "Our Team",
      //   key: "ourteam"
      // },
      // {
      //   label: "Success story",
      //   key: "successstories"
      // },
      // {
      //   label: "Blog",
      //   key: "blog"
      // },
      // {
      //   label: "Press Release",
      //   key: "pressrelease"
      // },
      // {
      //   label: "Out Sourcing Release",
      //   key: "outsourcing"
      // },
    ],
  },
  {
    label: "Who we are",
    key: "whoweare",
    children: [
      {
        label: "Scaleups",
        key: "scaleup"
      },
      {
        label: "Enterprises",
        key: "enterprise"
      },
      {
        label: "Product/SaaS firms",
        key: "SaaSfirms"
      },
      {
        label: "IT staff Agency",
        key: "it_staff_agency"
      },
    ],
  },
  {
    label: "Services",
    key: "services",
    children: [
      {
        label: "Generative AI",
        key: "generative-AI"
      },
      {
        label: "Product Development",
        key: "productDevelopment"
      },
      {
        label: "Web Development",
        key: "webDevelopment"
      },
      {
        label: "App Development",
        key: "appDevelopment"
      },
      {
        label: "ERP & CRM implementation",
        key: "erp-crm-implementation"
      },
      {
        label: "E-Commerce Development",
        key: "ecommerce"
      },
      {
        label: "DevOps",
        key: "devops"
      },
      {
        label: "Testing/QA",
        key: "testing"
      },
    ],
  },
  {
    label: "Careers",
    key: "careers",
    path: "/careers",
  },
];

const letUsKnowData = [
  {
    label: "5,000",
    title: "Active customers",
    sup: "+",
  },
  {
    label: "85",
    title: "of the fortune 100 trust us",
    sup: "",
  },
  {
    label: "54",
    title: "trillion clod transactions/mo",
    sup: "+",
  },
];

module.exports = {
  menuOptions,
  letUsKnowData,
};

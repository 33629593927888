import React, { useEffect } from "react";
import { Button, Col, Image, Row } from "antd";
import { useState } from "react";
import vision from "../../Assets/Vision.png";
import poised from "../../Assets/poised.png";
import Responsibility from "../../Assets/Responsibility.png";
import arrow1 from "../../Assets/arrow1.png";
import arrow2 from "../../Assets/arrow2.png";
import { randomIntFromInterval } from "../../Common/Components/CommonFunctions";
import styles from "../Styles/home.module.css";

const dynamicCurousalData = [
  {
    title: "Your Vision, Our Expertise",
    label: ": Thriving Since 2023",
    image: `${vision}`,
    imageLabel: "Say Hello",
  },
  {
    title: "Passion, Action, Responsibility,",
    label: "Team work is everything",
    image: Responsibility,
    imageLabel: "Team work",
  },
  {
    title: "Your data is poised for greatness",
    label: ": Explore our site",
    image: poised,
    imageLabel: "Explore",
  },
];

const DynamicCurousal = () => {
  const [switchText, setText] = useState({
    title: "Your Vision, Our Expertise",
    label: ": Thriving Since 2023",
    image: vision,
    imageLabel: "Say Hello",
  });

  useEffect(() => {
    setInterval(() => {
      let randomNumber = randomIntFromInterval(0, 2);
      setText({
        title: dynamicCurousalData[randomNumber].title,
        label: dynamicCurousalData[randomNumber].label,
        image: dynamicCurousalData[randomNumber].image,
        imageLabel: dynamicCurousalData[randomNumber].imageLabel,
      });
    }, 5000);
  }, []);

  return (
    <Row
      gutter={{
        xs: 12,
        sm: 12,
        md: 24,
        lg: 24,
      }}
      style={{ padding: "3rem 0 7rem 0" }}
    >
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div
          className="animate__animated animate__fadeIn  animate__infinite	 animate__slower"
          style={{ paddingTop: "1rem" }}
        >
          <div className={styles.titleCssTextwrapper}>{switchText.title}</div>
          <div className={styles.labelCssTextwrapper}>{switchText.label}</div>
        </div>
        <div style={{ position: "absolute", bottom: "1rem", left: "7rem" }}>
          <a href="#contact-form">
            <Button
              size={"large"}
              style={{
                borderRadius: "5px",
                background: "var(--dark-green, #031C1F)",
                boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                color: "#fff",
              }}
            >
              Enquire now
            </Button>
          </a>
        </div>
        <div style={{ position: "absolute", right: "-5rem", top: "6rem" }}>
          <Image
            preview={false}
            style={{
              width: "100%",
            }}
            src={arrow1}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <div className="animate__animated animate__rotateInDownRight animate__infinite animate__slower">
          <Image
            preview={false}
            style={{
              width: "100%",
              height: "290px",
              flexShrink: 0,
              borderRadius: "5px",
              boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
            }}
            src={switchText.image}
          />
          <div style={{ position: "absolute", right: "8rem", bottom: "-5rem" }}>
            <Image
              preview={false}
              style={{
                width: "100%",
              }}
              src={arrow2}
            />
          </div>
          <div style={{ position: "absolute", bottom: "-6rem", right: "1rem" }}>
            <Button size={"large"} className={styles.switchCard}>
              {switchText.imageLabel}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DynamicCurousal;

import React, { useState } from "react";
import { Col, Row, Typography } from "antd";
import styles from "../Styles/services.module.css";
import horizontalstyles from "../Styles/horizontalcard.module.css";
import { useMediaQuery } from "react-responsive";
const { Text } = Typography;

const ManualTestingSecondCard = ({ process }) => {
  const isMobile = useMediaQuery({maxWidth:576})
  const [selectedContent, setSelectedContent] = useState(process[0]);
  const changeContent = (name) => {
    let filteredContent = process.filter((e) => e.name === name);
    setSelectedContent(filteredContent[0]);
  };
  return (
    <div style={{margin:"10px"}} >
      <Row style={{justifyContent:"center"}}>
        <Col
          gutter={0}
          className={styles.containerStyle}
          style={{ background: "#D7F7D7" }}
          xs={24}
          sm={12}
          md={{span:12 }}
          lg={{ span:10  }}
          order={isMobile ? 2 :1 }
        >
          <img
            src={selectedContent?.image}
            alt="Development card"
            style={{ width: "100%", height: "100%" }}
          />
          <Text className={`${styles.imageText} ${styles.poppins}`}>
            {selectedContent?.desc}
          </Text>
        </Col>
        <Col
          style={{ background: "#D7F7D7" }}
          xs={24}
          sm={12}
          md={{span: 8 }}
          lg={{span: 6 }}
          order={isMobile ? 1 :2 }
        >
          <Row className={horizontalstyles.fulltext}>
            {process?.map((step,index) => (
              <Col
                key={step?.name}
                span={24}
                style={{ padding: "5px" }}
                className={` ${
                  step?.name === selectedContent?.name
                    ? horizontalstyles.selectedCard
                    : ""
                }`}
              >
                <Text
                  onClick={() => changeContent(step.name)}
                  className={`${horizontalstyles.eachtext} ${
                    step?.name === selectedContent?.name
                      ? horizontalstyles.bold
                      : ""
                  }`}
                >
                  {step?.name}
                </Text>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ManualTestingSecondCard;

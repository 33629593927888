import React from "react";
import { Button, Col, Image, Row, Typography } from "antd";
import drivenByDataImage from "../../Assets/drivenByData.png";
import styles from "../Styles/dataDriven_questions_PAR.module.css";
const { Title } = Typography;

const DrivenByData = () => {
  return (
    <section className={styles.bg_color_1} style={{ overflowX: "hidden" }}>
      <Row justify="center">
        <Title className={styles.drivenByDataTitle}>We're driven by DATA</Title>
      </Row>
      <Row
        style={{ padding: "2rem 3rem 3.5rem 3rem" }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        justify="center"
      >
        <Col md={1} lg={1}></Col>
        <Col xs={24} sm={24} md={9} lg={9}>
          <Image
            src={drivenByDataImage}
            preview={false}
            style={{ width: "100%" }}
          />
        </Col>
        <Col md={1} lg={1}></Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row justify="center" align="middle" style={{ height: "100%" }}>
            <Col span={24} className={styles.drivenByDataText}>
              <p>
                DATA is our commitment to cultivate a values-driven culture. We
                are passionate about building & delivering solutions that
                accelerate data innovations.
                <br />
                <span>
                  <span className={styles.coloredLetter}>D</span>o Good
                </span>
                <br /> Foster an inclusive culture where we treat each other
                with respect, fairness, and dignity.
                <br />
                <span>
                  <span className={styles.coloredLetter}>A</span>ct as One Team
                </span>
                <br />
                Connect, communicate & collaborate as one diverse team.
                <br />
                <span>
                  <span className={styles.coloredLetter}>T</span>hink
                  Customer-First
                </span>
                <br />
                Accelerate customer outcomes in everything we build and how we
                deliver.
                <br />
                <span>
                  <span className={styles.coloredLetter}>A</span>spire and
                  Innovate
                </span>
                <br />
                Continuously and fearlessly innovate through curiosity and
                learning.
              </p>
              <a href="#contact-form">
                <Button className={styles.btn}>Join US</Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Col md={1} lg={1}></Col>
      </Row>
    </section>
  );
};

export default DrivenByData;

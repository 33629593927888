import React from "react";
import BasicCard from "./Components/BasicCard";
import { Skills } from "../Insights/Skills";
import { ContactForm } from "../ContactForm/ContactForm";
import { ExcellenceAndSuccessCards, Insights } from "../Insights";
import { Typography, Layout, Space, Row, Col, Image, Card } from "antd";
import erpcrmBanner from "../Assets/ErpCrm/erpcrmBanner.png";
import erp from "../Assets/ErpCrm/erp.png";
import crm from "../Assets/ErpCrm/crm.png";
import integration from "../Assets/ErpCrm/Integration.png";
import reporting from "../Assets/ErpCrm/Reporting.png";
import automation from "../Assets/ErpCrm/Automation.png";
import centalizeddb from "../Assets/ErpCrm/Centraliseddb.png";
import salesAutomation from "../Assets/ErpCrm/Sales.png";
import contactManagement from "../Assets/ErpCrm/Contact.png";
import customerService from "../Assets/ErpCrm/CustomerService.png";
import marketingAutomation from "../Assets/ErpCrm/Marketing.png";
import styles from "./Styles/services.module.css";
import erpcrmstyles from "./Styles/erpcrm.module.css";


const { Text } = Typography;
const { Content } = Layout;

const ErpCrmImplementation = () => {
  const erpCrds = [
    {
      image: centalizeddb,
      title: "Centralized Database",
      content:
        "ERP systems provide a centralized database that allows different departments to store, retrieve, & use information in a cohesive manner.",
    },
    {
      image: integration,
      title: "Integration",
      content:
        "ERP integrates various business processes and functions, reducing the need for manual data entry & the chance of errors.",
    },
    {
      image: automation,
      title: "Automation",
      content:
        "Automation of routine tasks helps in improving efficiency & reducing operational costs.",
    },
    {
      image: reporting,
      title: "Reporting and Analytics",
      content:
        "ERP systems often include tools for reporting & analytics, enabling organizations to make data-driven decisions.",
    },
  ];
  const crmCards = [
    {
      image: contactManagement,
      title: "Contact Management",
      content:
        "Keeping track of customer information, communication history, and preferences.",
    },
    {
      image: salesAutomation,
      title: "Sales Automation",
      content:
        "ERP integrates various business processes and functions, reducing the need for manual data entry & the chance of errors.",
    },
    {
      image: customerService,
      title: "Customer Service and Support",
      content: "Managing customer inquiries, complaints & support requests.",
    },
    {
      image: marketingAutomation,
      title: "Marketing Automation",
      content:
        "Managing and automating marketing campaigns, tracking leads & analyzing customer behavior.",
    },
  ];
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ overflow: "hidden", fontWeight: 500 }}
    >
      {/* banner */}
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={erpcrmBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Manage their operations & interactions
        </Text>
      </Content>
      <Text className={erpcrmstyles.title}>ERP & CRM</Text>
      {/* ERP and CRM  */}
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card hoverable style={{ background: "#D7F7D7", fontWeight: "bold" }}>
            <Image src={erp} alt="erp" preview={false} />
            <br />
            <Text className={erpcrmstyles.font}>ERP</Text>
            <br />
            <Text className={erpcrmstyles.font}>
              (Enterprise Resource Planning)
            </Text>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card hoverable style={{ background: "#D7F7D7", fontWeight: "bold" }}>
            <Image src={crm} alt="crm" preview={false} />
            <br />
            <Text className={erpcrmstyles.font}>CRM</Text>
            <br />
            <Text className={erpcrmstyles.font}>
              (Customer Relationship Management)
            </Text>
          </Card>
        </Col>
      </Row>

      <Text className={erpcrmstyles.text}>
        are two types of software systems that businesses use to manage their
        operations & interactions with customers, respectively.
      </Text>
      <Text className={erpcrmstyles.title}>
        ERP (Enterprise Resource Planning)
      </Text>
      <Text className={erpcrmstyles.text}>
        ERP is a type of software that organizations use to manage and integrate
        various aspects of their business processes.
      </Text>
      {/* ERP cards */}
      <BasicCard cards={erpCrds} />
      {/* crm cards */}
      <Text className={erpcrmstyles.title}>
        CRM (Customer Relationship Management)
      </Text>
      <Text className={erpcrmstyles.text}>
        CRM is a type of software that focuses on managing a company's
        interactions with current and future customers. The primary goal is to
        improve business relationships, assist in customer retention, and drive
        sales growth.
      </Text>
      <BasicCard cards={crmCards} />
      {/* skills */}
      <Skills/>
      {/* contact from */}
      <ContactForm/>
      <p style={{ fontSize: "1.8em",fontFamily:"Poppins,sans-serif" }}>One year of excellence & success</p>
      <ExcellenceAndSuccessCards/>
      <Insights />
    </Space>
  );
};

export { ErpCrmImplementation };
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'antd';
import "react-phone-input-2/lib/style.css";
import styles from '../Styles/careers.module.css';

const CareersCard = ({job}) => {
    return (
        <>
            <Card
                style={{
                    width: 300,
                    lineHeight: "0px",
                    textAlign: "left",
                    border: "2px solid #004952",
                    marginBottom: "10px",
                    fontSize: "1.2em",
                }}
            >
                <h1 style={{ padding: "0.5rem 0" }}>{job?.name}</h1>
                <p
                    style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        paddingBottom: "1rem",
                    }}
                >
                    {job?.job}
                </p>
                <p>Experience: {job?.experience}</p>
                <br />
                <p style={{ paddingBottom: "1rem" }}>Location: {job?.location}</p>
              <Link to={job?.applyLink} target="_blank" rel="noopener noreferrer" ><Button className={styles.applyStyle}>Apply</Button></Link> 
            </Card>
        </>
    )
}

export default CareersCard;
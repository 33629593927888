import React from "react";
import HorizontalCard from "./Components/HorizontalCard";
import ManualTestingSecondCard from "./Components/ManualTestingSecondCard";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import { ContactForm } from "../ContactForm/ContactForm";
import { Image, Layout, Space, Typography } from "antd";
import testingBanner from "../Assets/Testing/TestingBanner.png";
import testingProcess from "../Assets/Testing/TestingProcess.png";
import efficiency from "../Assets/Testing/efficiency.png";
import Reusability from "../Assets/Testing/Reusability.png";
import Accuracy from "../Assets/Testing/Accuracy.png";
import Regression from "../Assets/Testing/RegressionTesting.png";
import ParallelExecution from "../Assets/Testing/ParallelExecution.png";
import ManualTestingImage from "../Assets/Testing/ManualTesting.png";
import styles from "./Styles/services.module.css";
import testingStyles from "./Styles/testing.module.css";

const { Content } = Layout;
const { Text } = Typography;

const Testing = () => {
  const process = [
    {
      image: efficiency,
      desc: "Automation testing allows for the quick execution of repetitive & time-consuming test cases, enabling faster feedback on the software's quality.",
      name: "Efficiency",
    },
    {
      image: Reusability,
      desc: "Automated tests can be reused across different versions of the software or in various test scenarios, saving time and effort.",
      name: "Reusability",
    },
    {
      image: Accuracy,
      desc: "Automation tools perform tests with precision & eliminate the possibility of human errors, ensuring more reliable and consistent results.",
      name: "Accuracy",
    },
    {
      image: Regression,
      desc: "Automated tests are particularly useful for regression testing, where previously executed test cases are rerun to ensure that new change in the software haven't introduced new defects.",
      name: "Regression Testing",
    },
    {
      image: ParallelExecution,
      desc: "Automation allows for the simultaneous execution of tests on different devices & platforms, facilitating comprehensive testing across various configurations.",
      name: "Parallel Execution",
    },
  ];
  const manualTestingProcess = [
    {
      image: ManualTestingImage,
      desc: "Manual testing relies on human testers to perform various test scenarios, such as inpu data validation, user interface interactions, & system behavior observation.",
      name: "Human Intervention",
    },
    {
      image: ManualTestingImage,
      desc: "Testers often use exploratory testing techniques to simulate real-world user scenarios and discover unexpected defects that may not be covered by predefined test cases.",
      name: "Exploratory Testing",
    },
    {
      image: ManualTestingImage,
      desc: "Testers can perform ad-hoc testing, where they test the application without predefined test cases, based on their experience and intuition.",
      name: "Ad-Hoc Testing",
    },
    {
      image: ManualTestingImage,
      desc: "Manual testing is well-suited for evaluating the user experience, usability, and overall satisfaction of end-users interacting with the software.",
      name: "User Experience Testing",
    },
    {
      image: ManualTestingImage,
      desc: "Testers have the flexibility to adapt test cases & testing strategies based on the evolving requirements and changes in the software.",
      name: "Flexible and Iterative",
    },
    {
      image: ManualTestingImage,
      desc: "Manual testing is often used in the early phases of the software development life cycle (SDLC) such as unit testing, integration testing, & system testing.",
      name: "Initial Testing Phases",
    },
  ];
  return (
    <Space direction="vertical" size="middle">
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={testingBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Process of creating software applications <br /> & maintaining
          software applications
        </Text>
      </Content>
      <Text className={testingStyles.text}>
        Software testing is a process of evaluating & verifying that a software
        application or system works as intended.
      </Text>
      <Image
        style={{ width: "70%" }}
        src={testingProcess}
        alt="Testing process"
        preview={false}
      />
      <Text className={styles.title}>Automation Testing process</Text>
      <HorizontalCard process={process} />
      <Text className={styles.title}>Manual Testing process</Text>
      <ManualTestingSecondCard process={manualTestingProcess} />
      <p style={{ fontSize: "1.8em", fontFamily: "Poppins,sans-serif" }}>
        One year of excellence & success
      </p>
      <ExcellenceAndSuccessCards />
      <Skills />
      <ContactForm />
      <Insights />
    </Space>
  );
};

export { Testing };

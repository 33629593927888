import React from "react";
import { Layout, Row } from "antd";
import { Filler } from "./Filler";
import { letUsKnowData } from "../../Common/Components/CommonData";
import styles from "../Styles/letUsKnow.module.css";

const { Content } = Layout;
const LetUsKnow = () => {
  return (
    <>
      <Content
        style={{ padding: "1rem 2rem" }}
        className={styles.letUsKnowContainer}
      >
        <Row className={styles.letUsKnowTitle}>Let us Know</Row>
        <Row className={styles.letusKnowContent}>
          Founded in 2023, PragmaSoft is a India-based software company with
          CMMi 3 standards providing a pre-vetted pool of top 3% software
          developers from our 10+ global locations. Partner with us and say
          "Hello" to Innovation.
        </Row>
      </Content>
      <Content>
        <Filler />
      </Content>
      {/* <Content className={styles.flex}>
        {letUsKnowData?.map((data) => (
          <div key={data?.title} className={styles.letusknowcard}>
            <Row className={styles.cardLabel}>
              {data?.label}
              <sup>{data?.sup}</sup>
            </Row>
            <Row className={styles.cardTitle}>{data?.title}</Row>
          </div>
        ))}
      </Content> */}
    </>
  );
};

export default LetUsKnow;

import React from "react";
import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import LetUsKnow from "./Components/LetUsKnow";
import Providings from "./Components/Providings";
import DynamicCurousal from "./Components/DynamicCurousal";
import { ContactForm } from "../ContactForm";
import MobileCarousel from "./Components/MobileCarousel";
import DataComesToLife from "./Components/DataComesToLife";
import DrivenByData from "./Components/DrivenByData";
import ReadyForQuestions from "./Components/ReadyForQuestions";
import PAR from "./Components/PAR";
const { Content } = Layout;

function Home() {
  const isMobile = useMediaQuery({ maxWidth: 992 });

  return (
    <Layout style={{ height: "100%", background: "#fff" }}>
      <Content style={{ padding: "0", overflowX: "hidden" }}>
        {isMobile ? <MobileCarousel /> : <DynamicCurousal />}
      </Content>
      <Content>
        <LetUsKnow />
      </Content>
      <Content style={{ background: "#fff" }}>
        <DrivenByData />
      </Content>
      <Content style={{ background: "#fff" }}>
        <DataComesToLife />
      </Content>
      <Content style={{ background: "#fff" }}>
        <ReadyForQuestions />
      </Content>
      <Content style={{ background: "#fff" }}>
        <PAR />
      </Content>
      {/* <Content>
        <Contributions />
      </Content> */}
      {/* <Content>
        <Providings />
      </Content> */}
      <Content>
        <ContactForm />
      </Content>
    </Layout>
  );
}
export { Home };

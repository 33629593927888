import React, { useEffect, useState } from "react";
import { ContactForm } from "../ContactForm/ContactForm";
import { Col, Layout, Row } from "antd";
import styles from "./Styles/outsourcing.module.css";
import outSourcingBanner from "../Assets/Outsourcing/outsourcingBanner.png";
import ideasImg from "../Assets/Outsourcing/sideImage.png";
import software from "../Assets/Outsourcing/software.png";
import testing from "../Assets/Outsourcing/Testing.png";
import digital from "../Assets/Outsourcing/Digital.png";
import Appmaintainence from "../Assets/Outsourcing/AppMaintainence.png";
import cloud from "../Assets/Outsourcing/Cloud.png";
import consulting from "../Assets/Outsourcing/consulting.png";
import dedicated from "../Assets/Outsourcing/Dedicated.png";
import hire from "../Assets/Outsourcing/Hire.png";

const { Content } = Layout;

const Outsourcing = () => {
  const services = [
    "Design Services",
    "Software Development Services",
    "QA & Testing Services",
    "Digital Transformation Services",
    "App Maintainance & Support Services",
    "Cloud Migration Services",
    "IT Strategy & Consulting Services",
    "Dedicated Services",
    "Hire Developers",
  ];
  const images = [
    ideasImg,
    software,
    testing,
    digital,
    Appmaintainence,
    cloud,
    consulting,
    dedicated,
    hire,
  ];
  const [serviceName, setServiceName] = useState(services[0]);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  useEffect(() => {
    let currentIndex = 0;
    const interValid = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setSelectedImage(images[currentIndex]);
      setServiceName(services[currentIndex]);
    }, 2000);
    return () => clearInterval(interValid);
    // eslint-disable-next-line
  }, [setSelectedImage]);

  return (
    <>
      <Content className={styles.containerStyle}>
        <img
          className={styles.image}
          src={outSourcingBanner}
          alt="Outsourcing banner"
        />
        <div className={styles.textStyle}>
          <div>Out sourcing</div>
          <div>Streamline operations with our IT outsourcing</div>
        </div>
      </Content>
      <Content>
        <p className={styles.outsourcingHeading}>
          Tailored solutions, 24/7 support, cost-effective strategies
        </p>
        <div style={{ fontWeight: "500" }}>
          <div>Elevate performance and focus on core business.</div>
          <div>Your IT, our expertise.</div>
          <div>
            Provide high-quality software products for clients worldwide.
          </div>
        </div>
      </Content>
      <Content style={{ margin: "30px" }}>
        <Row style={{ justifyContent: "center", alignItems: "center" }}>
          <Col xs={24} sm={12} md={12} lg={9} xl={9}>
            {services?.map((service) => (
              <p
                style={{ fontWeight: service === serviceName ? "bold" : 400 }}
                key={service}
                className={styles.sideText}
              >
                {service}
              </p>
            ))}
          </Col>
          <Col xs={24} sm={12} md={12} lg={9} xl={9}>
            <img src={selectedImage} alt="ideas" className={styles.ideasImg} />
          </Col>
        </Row>
      </Content>
      <Content>
        <ContactForm />
      </Content>
    </>
  );
};

export { Outsourcing };

import React, { useState } from "react";
import { Carousel, Image } from "antd";
import vision from "../../Assets/Vision.png";
import poised from "../../Assets/poised.png";
import Responsibility from "../../Assets/Responsibility.png";
import styles from "../Styles/carousel.module.css";

const MobileCarousel = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const texts = [
    "Your vision,Our Expertise",
    "Your data is poised for greatness",
    "Passion, Action, Responsibility",
  ];
  const labels = [
    ":Thriving Since 2023",
    ":Explore our site",
    ":Thriving Since 2023",
  ];

  const moveIn = () => {
    document.getElementById("movingText").style.left = "-300px";
  };

  let moveOut = (from, to) => {
    setCurrentSlideIndex(from);
    document.getElementById("movingText").style.left = "0";
  };

  return (
    <div>
      <Carousel
        effect="scrollx"
        beforeChange={moveIn}
        afterChange={moveOut}
        autoplay
        dots={false}
        autoplaySpeed={3000}
        speed={500}
      >
        <Image
          src={vision}
          alt="vision"
          preview={false}
          // height="400px"
          // width="100%"
        />
        <Image src={poised} alt="poised" preview={false} />
        <Image src={Responsibility} alt="Vision" preview={false} />
      </Carousel>
      <div id="movingText" className={`${styles.movingText}`}>
        <div className={styles.movingTextTitle}>{texts[currentSlideIndex]}</div>
        <div className={styles.movingTextLabel}>
          {labels[currentSlideIndex]}
        </div>
      </div>
    </div>
  );
};

export default MobileCarousel;

import React from "react";
import { Col, Image, Layout, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import ecommerceBanner from "../Assets/Ecommerce/E1.png";
import webEcommerce from "../Assets/Ecommerce/E2.png";
import mobileEcommerce from "../Assets/Ecommerce/E3.png";
import CRMEcommerce from "../Assets/Ecommerce/E4.png";
import ecommerceDevelopment from "../Assets/Ecommerce/ecommerceDev.png";
import infoMobile from "../Assets/infoMobile.png";
import info from "../Assets/info.png";
import DevelopmentCard from "./Components/DevelopmentCard";
import styles from "./Styles/ecommerce.module.css";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import { ContactForm } from "../ContactForm";
const { Text } = Typography;
const { Content } = Layout;

const stages = [
  {
    image: webEcommerce,
    desc: "E-commerce website development involves creating online platforms that facilitate buying and selling of goods/services, incorporating features like secure transactions, user-friendly interfaces, and efficient payment gateways.",
    title: "eCommerce Website Development",
  },
  {
    image: mobileEcommerce,
    desc: "E-commerce mobile application development involves creating software for online shopping, enabling users to browse products, make purchases, and manage transactions seamlessly on their mobile devices.",
    title: "eCommerce Mobile App Development",
  },
  {
    image: CRMEcommerce,
    desc: "eCommerce CRM services streamline customer relationship management for online businesses, enhancing customer interactions, managing data, and optimizing marketing strategies to drive sales and customer satisfaction.",
    title: "eCommerce CRM Services",
  },
];

const Ecommerce = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });

  return (
    <>
      <section className={styles.ecommerceBanner}>
        <img src={ecommerceBanner} alt="Ecommerce" className={styles.image} />
        <div className={styles.overlay}>
          <div className={styles.text}>
            Creating, building, & maintaining an online platform
          </div>
        </div>
      </section>

      {/* Ecommerce development */}
      <Row className={styles.ecomm_row}>
        <Col span={24}>
          <Image
            src={ecommerceDevelopment}
            alt="Ecommerce development"
            preview={false}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Col>
      </Row>

      {/*  */}
      <div style={{ margin: "30px 0" }}>
        <div style={{ marginBottom: "25px" }}>
          <Text className={styles.heading}>We Expertise in</Text>
        </div>

        {/* Development card */}
        <DevelopmentCard stages={stages} />
      </div>

      {/* Services flow */}
      <Content style={{ margin: "30px 0" }}>
        <p style={{ fontSize: "1.3em", fontFamily: "Poppins,sans-serif" }}>
          Here is our{" "}
          <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
            End to End
          </span>{" "}
          services to take you next level
        </p>
        <p style={{ fontSize: "1.1em", fontFamily: "Poppins,sans-serif" }}>
          Services we are doing to improve your business & agile work process
        </p>
        {isMobile ? (
          <img src={infoMobile} alt="Process" style={{ width: "70%" }} />
        ) : (
          <img src={info} alt="Process" style={{ width: "90%" }} />
        )}
      </Content>

      {/* Skills */}
      <Content style={{ marginTop: "50px" }}>
        <Skills />
      </Content>

      {/* Contact Form */}
      <ContactForm />

      {/* Excellence */}
      <ExcellenceAndSuccessCards />

      {/* Insights */}
      <Insights />
    </>
  );
};

export { Ecommerce };

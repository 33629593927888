import React from "react";
import { Button, Col, Row } from "antd";
import insightspic1 from "../Assets/insights1.png";
import insightspic2 from "../Assets/insights2.png";
import styles from './Styles/insights.module.css';

const Insights = () => {
  return (
    <div style={{margin:"30px"}}>
      <h1 style={{textDecoration:"underline",fontFamily:"Poppins,sans-serif"}}>Insights</h1>
      <b style={{fontFamily:"Poppins,sans-serif"}}>Read our blogs to stay updated with trends</b>
      <Row gutter={[16,16]} className={styles.insights}>
        <Col sm={24} md={12} lg={8}>
          <img src={insightspic1} alt="AI model" />
          <p  className={styles.insightsText}>Using AI and Large Language Models for Nuanced</p>
          <Button className={styles.viewArticle}>View article</Button>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <p style={{color:"#005cc9",fontSize:"1.6em",fontWeight:"500",fontFamily:"Poppins,sans-serif"}}>Generative AI - From Hype to Business Impact</p>
          <p className={styles.insightsMidtext}>
            Generative AI has surged onto the global stage, disrupting
            traditional business practices and models. As leaders, innovators,
            and analysts, we are engaged in a race to effectively harness and
            apply generative AI tools. While we marvel at the…
          </p>
          <Button className={styles.viewArticle}>View article</Button>
        </Col>
        <Col sm={24} md={{offset:0}} lg={8}>
          <img src={insightspic2} alt="AI native" />
          <p className={styles.insightsText}>Preparing for a New Breed of Customers: The Gen AI Natives</p>
          <Button className={styles.viewArticle}>View article</Button>
        </Col>
      </Row>
    </div>
  );
};

export { Insights };

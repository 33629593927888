import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ContactForm } from "../ContactForm/ContactForm";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import { Card, Col, Image, Layout, Row, Space, Typography } from "antd";
import GenerativeBanner from "../Assets/GenerativeAI/GenerativeAIbanner.png";
import ai from "../Assets/GenerativeAI/ai.png";
import creative from "../Assets/GenerativeAI/creative.png";
import generativeRobot from '../Assets/GenerativeAI/GenerativeRobot.png';
import mobileRobot from '../Assets/GenerativeAI/MobileRobot.png';
import styles from "./Styles/services.module.css";

const { Content } = Layout;
const { Text } = Typography;

const GenerativeAI = () => {
  const isMobile = useMediaQuery({maxWidth:576})
  const offerings = [
    {
      image: ai,
      title: "Generative AI",
      desc: "Create hyper-personalized content for a wide range of personas with PragmaSoft Generative AI services suite. Our AI consultants identify use cases, design approaches, & build algorithms with tools based on industry best practices and experience.",
    },
    {
      image: creative,
      title: "AI Transformation",
      desc: "Jumpstart your AI Transformation journey by embracing PragmaSoft holistic approach to identify AI opportunities in your organization. We use design thinking to uncover hidden opportunities & unlock value through a prioritized roadmap starting with quick wins.",
    },
  ];
  const diffeerentiators = [
    {
      color:"rgba(255, 229, 229, 0.60)",
      content:"Intelligent too led discovery"
    },
    {
      color:"rgba(255, 254, 229, 0.60)",
      content:"Holistic approach using design thinking aimed at the target persona."
    },
    {
      color:"rgba(235, 255, 229, 0.60)",
      content:"Rapid planning for a de-risked journey to the cloud (MVC)."
    },
    {
      color:"rgba(229, 255, 253, 0.60)",
      content:"Assurance focused & outcome-driven approach."
    },
    {
      color:"rgba(245, 229, 255, 0.60)",
      content:"Strategic thinking & thought leadership."
    }
  ];
  const [offeringDesc, setOfferingDesc] = useState(offerings[0]);
  const handleOfferingDesc = (title) => {
    let filteredOfferings = offerings?.filter((e) => e.title === title);
    setOfferingDesc(filteredOfferings[0]);
  };
  return (
    <Space direction="vertical" size="middle" style={{overflow:"hidden"}}>
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={GenerativeBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Build Generative AI Applications
          <br />
          <span className={styles.secondText}>
            Secure and Easy Ways to Build Generative AI Applications Quickly
          </span>
        </Text>
      </Content>
      <Text className={styles.heading}>Top AI use cases</Text>
      {!isMobile ?
      <Image style={{width:"90%"}} src={generativeRobot} alt="use cases" preview={false}/> :
      <Image style={{width:"95%"}} src={mobileRobot} alt="use cases" preview={false} />}
      <Text className={styles.heading}>Our Offerings</Text>
      <Row gutter={[16, 16]} >
        {offerings?.map((offering) => (
          <Col
            key={offering?.desc}
            xs={24}
            sm={12}
            onClick={() => handleOfferingDesc(offering?.title)}
            className={styles.offering}
            style={{
              opacity: `${
                offering?.title === offeringDesc?.title ? `1` : ` 0.5`
              }`
            }}
          >
            <Image src={offering?.image} alt="Generative AI" preview={false} />
            <br />
            <Text className={styles.title}>{offering?.title}</Text>
          </Col>
        ))}
      </Row>
      <div className={styles.offeringDesc}>{offeringDesc?.desc}</div>
      <Text className={styles.heading}>Our Differentiators</Text>
      <Row gutter={[16,16]} style={{justifyContent:'center',alignItems:"center"}}>
        {diffeerentiators?.map((differentiator) => (
          <Col key={differentiator?.content}>
            <Card className={styles.differentiatorsCard} style={{background:`${differentiator?.color}`}}>{differentiator?.content}</Card>
          </Col>
        ))}
      </Row>
      <ContactForm />
      <p style={{ fontSize: "1.8em", fontFamily: "Poppins,sans-serif" }}>
        One year of excellence & success
      </p>
      <ExcellenceAndSuccessCards />
      <Skills/>
      <Insights />
    </Space>
  );
};

export { GenerativeAI };

import React from "react";
import { Col, Image, Row, Typography } from "antd";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import { ContactForm } from "../ContactForm";
import BenefitsOfDevOpsTeam from "../Assets/Devops/benefits.png";
import devopsBanner from "../Assets/Devops/banner.png";
import devopsDiagram from "../Assets/Devops/devops.png";
import styles from "./Styles/devops.module.css";
const { Title } = Typography;

const DevOps = () => {
  return (
    <>
      {/* Banner */}
      <section className={styles.devopsBanner}>
        <img src={devopsBanner} alt="Press Release" className={styles.image} />
        <div className={styles.overlay}>
          <div className={styles.text}>
            "Collaborative approach integrating development & operations for
            efficient software delivery."
          </div>
        </div>
      </section>

      {/* Devops diagram */}
      <Row className={styles.devops_row}>
        <Col span={24}>
          <Image
            src={devopsDiagram}
            alt="Devops diagram"
            preview={false}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Col>
      </Row>

      {/* Excellence card */}
      <Row>
        <Col span={24}>
          <Title className={styles.excellenceTitle}>
            One year of excellence and success
          </Title>
        </Col>
        <Col span={24}>
          <ExcellenceAndSuccessCards />
        </Col>
      </Row>

      {/* Benefits of Devops team section */}
      <Row style={{ margin: "50px 0" }}>
        <Col span={24}>
          <Image
            src={BenefitsOfDevOpsTeam}
            alt="Benefits of Devops team"
            preview={false}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Col>
      </Row>

      {/* Skills */}
      <Skills />

      {/* Contact Form */}
      <ContactForm />

      {/* Insigths */}
      <Insights />
    </>
  );
};

export { DevOps };

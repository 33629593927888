import React from "react";
import { useMediaQuery } from "react-responsive";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import CaseStudies from "../Common/Components/CaseStudies";
import { ContactForm } from "../ContactForm/ContactForm";
import { Image, Layout, Space, Typography } from "antd";
import productBanner from "../Assets/ProductDevelopment/ProductBanner.png";
import productProcess from "../Assets/ProductDevelopment/PRODUCTDEVELOPMENTProcess.png";
import productMobile from "../Assets/ProductDevelopment/ProductprocessMobile.png";
import styles from "./Styles/services.module.css";
const { Content } = Layout;
const { Text } = Typography;

const ProductDevelopment = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Space direction="vertical" size="middle">
      <Content className={styles.containerStyle}>
        <img className={styles.image} src={productBanner} alt="company" />
        <Text className={`${styles.textStyle} ${styles.poppins}`}>
          Innovate, Code, Test, Deploy, Iterate
          <br />
          <span className={styles.secondText}>
            {" "}
            Agile development, user-centric design, seamless integration,
            scalable architecture
          </span>
        </Text>
      </Content>
      <Content style={{ margin: "20px" }}>
        <Text
          style={{
            fontSize: "1.6em",
            textDecoration: "underline",
            fontFamily: "Popins,sans-serif",
          }}
        >
          Stages we do
        </Text>
        <br />
        <Text style={{ fontSize: "1.3em", fontFamily: "Popins,sans-serif" }}>
          Product development typically involves several stages, each crucial
          for creating a successful product
        </Text>
      </Content>
      {isMobile ? (
        <Image width={250} src={productMobile} preview={false} alt="process" />
      ) : (
        <Image
          style={{ width: "85%" }}
          src={productProcess}
          alt="Stages"
          preview={false}
        />
      )}
      <Skills />
      <CaseStudies />
      <ContactForm />
      <p style={{ fontSize: "1.8em", fontFamily: "Poppins,sans-serif" }}>
        One year of excellence & success
      </p>
      <ExcellenceAndSuccessCards />
      <Insights />
    </Space>
  );
};

export { ProductDevelopment };

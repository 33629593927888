import React from 'react';
import styles from '../Styles/caseStudies.module.css';
import { Button, Card, Divider } from 'antd';

const CaseStudies = () => {
    const case_studies = [{
        title: "Real Estate",
        label: "Real Estate",
        desc: "PragmaSoft Crafts a Complete Real Estate Transaction Management Solution.",
        location: "Colorado,US"
    }, {
        title: "Grow App",
        label: "Edtech",
        desc: "Building a Flexible, Secure eCommerce Site for an Education Firm.",
        location: "Mumbai, India"
    }, {
        title: "CRM Service Provider",
        label: "CRM",
        desc: "PragmaSoft Builds Service Software Mobile Applications Atop a CRM Product.",
        location: "Pune, India"
    }];

    return (
        <>
            <p className={styles.successstoriesTitle}>Case studies</p>
            <div className={styles.allCards}>
                {
                    case_studies?.map((cas) => (
                        <Card key={cas.title} hoverable className={styles.caseStudycard}>
                            <p style={{ fontSize: "1.5em" }}>{cas?.title}</p>
                            <Divider style={{ marginBottom: "15px", background: "#004952" }} />
                            <div style={{ textAlign: "left" }}>
                                <Button style={{ background: "#DDD", fontWeight: "400",fontFamily:"Poppins,sans-serif" }}> {cas?.label}</Button>
                                <p>{cas?.desc}</p>
                                <p className={styles.allCards}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M14 2.25C16.5859 2.25 19.0658 3.27723 20.8943 5.10571C22.7228 6.93419 23.75 9.41414 23.75 12C23.75 16.12 20.855 20.61 15.14 25.518C14.8222 25.791 14.4171 25.9409 13.9981 25.9405C13.5792 25.9402 13.1743 25.7895 12.857 25.516L12.479 25.188C7.017 20.408 4.25 16.028 4.25 12C4.25 9.41414 5.27723 6.93419 7.10571 5.10571C8.93419 3.27723 11.4141 2.25 14 2.25ZM14 8.25C13.0054 8.25 12.0516 8.64509 11.3483 9.34835C10.6451 10.0516 10.25 11.0054 10.25 12C10.25 12.9946 10.6451 13.9484 11.3483 14.6517C12.0516 15.3549 13.0054 15.75 14 15.75C14.9946 15.75 15.9484 15.3549 16.6517 14.6517C17.3549 13.9484 17.75 12.9946 17.75 12C17.75 11.0054 17.3549 10.0516 16.6517 9.34835C15.9484 8.64509 14.9946 8.25 14 8.25Z" fill="#430045" />
                                    </svg>{cas?.location}
                                </p>
                            </div>
                        </Card>)
                    )
                }
            </div>
        </>
    )
}

export default CaseStudies;

import { Layout, Space } from "antd";
import React from "react";
import styles from "./Styles/static.module.css";
import privacyPolicy from "../Assets/PrivacyPolicy.png";
const { Content } = Layout;

function PrivacyPolicy() {
  return (
    <Layout>
      <Content className={styles.imageStyle}>
        <img
          src={privacyPolicy}
          className={styles.image}
          alt="privacy policy"
        />
        <div className={styles.textStyle}>Privacy Policy</div>
      </Content>
      <Content className={styles.totalContent}>
        <Content style={{ fontSize: "1.1em" }}>
          <p> Effective Date : 01/01/2023</p>
          <p> Welcome to PragmaSoft!</p>
          <p>
            This Privacy Policy explains how we collect, use, disclose, &
            safeguard your personal information when you visit our website & use
            our services.
          </p>
        </Content>
        <Space direction="vertical">
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Information We Collect:</h1>
            <ul>
              <li>Personal Information:</li>
              <p>
                We may collect personal information, such as your name, email
                address & contact details when you voluntarily provide it to us.{" "}
              </p>
              <li>
                Automated Information: We may automatically collect certain
                information about your device & usage patterns such as IP
                addresses, browser types, and page views, to improve our
                services and website functionality.{" "}
              </li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>How We Use Your Information:</h1>
            <p>
              We use the information we collect for the following purposes:{" "}
            </p>
            <ul>
              <li>To provide and maintain our services.</li>
              <li>To personalize your experience and improve our website.</li>
              <li>
                To communicate with you about our services, updates, and
                promotions.
              </li>
              <li>To respond to your inquiries and support requests.</li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Information Sharing:</h1>
            <ul>
              <li>
                We do not sell, trade, or rent your personal information to
                third parties.
              </li>
              <li>
                However, we may share information with trusted third-party
                service providers
              </li>
              <li>
                who assist us in operating our website or conducting our
                business.
              </li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Cookies and Tracking Technologies:</h1>
            <ul>
              <li>
                We use cookies & similar tracking technologies to enhance your
                experience on our website.
              </li>
              <li>
                You can manage your cookie preferences through your browser
                settings.
              </li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Security:</h1>
            <ul>
              <li>
                We take reasonable measures to protect your personal information
                from unauthorized access, disclosure, alteration, & destruction.
              </li>
              <li>
                However, no method of transmission over the internet or
                electronic storage is completely secure.
              </li>
              <li>we cannot guarantee absolute security.</li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Changes to this Privacy Policy:</h1>
            <ul>
              <li>We may update our Privacy Policy from time to time.</li>
              <li>
                Any changes will be posted on this page, and the effective date
                will be updated accordingly.
              </li>
            </ul>
          </Content>
          <Content style={{ fontSize: "1.1em" }}>
            <h1>Contact Us:</h1>
            <p>
              If you have any questions or concerns about this Privacy Policy,
            </p>
            <p>Please contact us on : </p>
            <ul>
              <li>contact@pragmasoft.in</li>
              <li>+91 848-308-9257</li>
            </ul>
          </Content>
          <p style={{ textAlign: "center" }}>
            By using our website, you consent to the terms of this Privacy
            Policy.
          </p>
        </Space>
      </Content>
      <Content></Content>
    </Layout>
  );
}
export { PrivacyPolicy };

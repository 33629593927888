import React from "react";
import styles from "./Styles/static.module.css";
import termsofservice from "../Assets/Termsofservice.png";
import { Layout, Space } from "antd";
const { Content } = Layout;

function TermsofService() {
  return (
    <>
      <Layout>
        <Content className={styles.imageStyle}>
          <img
            src={termsofservice}
            className={styles.image}
            alt="Terms of Service"
          />
          <div className={styles.textStyle}>Terms of Service</div>
        </Content>
        <Content className={styles.totalContent}>
          <Space
            size="small"
            direction="vertical"
            style={{ fontSize: "1.1em" }}
          >
            <p>Last Updated: 01/01/2023</p>
            <Content style={{ fontSize: "1em" }}>
              <h1>Welcome to PragmaSoft!</h1>
              <p>
                By using our website and services, you agree to comply with and
                be bound by the following terms & conditions of use. Please
                review these terms carefully before using our site. If you do
                not agree with any part of these terms, please do not use our
                website.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Acceptance of Terms</h1>
              <p>
                y accessing this website, you agree to be bound by these Terms
                of Service, all applicable laws & regulations, & agree that you
                are responsible for compliance with any applicable local laws
              </p>
              <p>
                If you do not agree with any of these terms, you are prohibited
                from using or accessing this site.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Use of the Website</h1>
              <p>
                a. You must be at least 18 years old to use this website. By
                using this website, you warrant that you are at least 18 years
                of age.
              </p>
              <p>
                b. You agree to use this website only for lawful purposes and in
                accordance with these Terms of Service.
              </p>
              <p>
                c. You are responsible for maintaining the confidentiality of
                your account and password & for restricting access to your
                computer.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Intellectual Property Rights</h1>
              <p>
                a. The content on this website, including but not limited to
                text, graphics, logos, images, and software, is the property of
                [Your Company Name] and is protected by copyright and other
                intellectual property laws.
              </p>
              <p>
                b. You may not modify, reproduce, distribute, display, or create
                derivative works from any of the content on this website.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>User Submissions</h1>
              <p>
                a. Users may have the oppurtunity to submit content, such as
                reviews or comments.
              </p>
              <p>
                b. PragmaSoft reserves the right to remove any content that
                violates these Terms of Service or is otherwise objectionable.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Privacy Policy</h1>
              <p>
                Your use of this website is also governed by our Privacy Policy,
                which can be found at [link to your privacy policy].
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Disclaimer</h1>
              <p>
                a. The content on this website is provided "as is" without any
                warranties, expressed or implied.
              </p>
              <p>
                b. PragmaSoft makes no representations or warranties regarding
                the accuracy, completeness, or eliability of the content on this
                website.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Limitation of Liability</h1>
              <p>
                To the fullest extent permitted by law, PragmaSoft shall not be
                liable for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenues, whether
                incurred directly or indirectly, or any loss of data, use,
                goodwill, or other intangible losses, resulting from:
              </p>
              <p>
                a. Your access to or use of or inability to access or use the
                website.
              </p>
              <p>
                b. Any conduct or content of any third party on the website.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Governing Law</h1>
              <p>
                These Terms of Service are governed by and construed in
                accordance with the laws of [your jurisdiction], without regard
                to its conflict of law principles.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Changes to Terms</h1>
              <p>
                PragmaSoft reserves the right to revise these Terms of Service
                at any time. By using this website, you are agreeing to be bound
                by the then-current version of these Terms of Service.
              </p>
            </Content>
            <Content style={{ fontSize: "1em" }}>
              <h1>Contact Us:</h1>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                <br />
                please contact us on :
              </p>
              <li>contact@pragmasoft.in</li>
              <li>+91 848-308-9257</li>
            </Content>
          </Space>
        </Content>
      </Layout>
    </>
  );
}
export { TermsofService };

import React from "react";
import { Card, Col, Image, Layout, Row, Typography } from "antd";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { PiCaretDownBold } from "react-icons/pi";
import ITBanner from "../Assets/IT staff/banner.png";
import person_1 from "../Assets/IT staff/IT1.png";
import person_2 from "../Assets/IT staff/IT2.png";
import person_3 from "../Assets/IT staff/IT3.png";
import person_4 from "../Assets/IT staff/IT4.png";
import person_5 from "../Assets/IT staff/IT5.png";
import card_center_image from "../Assets/IT staff/image_center.png";
import stevenPark from "../Assets/IT staff/C3.png";
import williamKart from "../Assets/IT staff/C2.png";
import samMichalle from "../Assets/IT staff/C1.png";
import laraClinton from "../Assets/IT staff/C4.png";
import matheshKumar from "../Assets/IT staff/C5.png";
import { ContactForm } from "../ContactForm";
import { ExcellenceAndSuccessCards, Insights, Skills } from "../Insights";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Styles/itStaffAgency.module.css";
const { Text } = Typography;
const { Content } = Layout;

const teamData = [person_1, person_2, person_3, person_4, person_5];

const hiringData = [
  {
    title: "Bigger in sharpness",
    sub: "Build Your Digital Dream Team.",
    color: "#FFE5E5CC",
  },
  {
    title: "The team is yours",
    sub: `"Your team, our responsibility."`,
    color: "#EBFFE5CC",
  },
  {
    title: "One cost, infinite fulfillment",
    sub: "Complete Growth, Single Investment",
    color: "#FFFEE5CC",
  },
  {
    title: "We only say, Done",
    sub: `"Timely completion, never meet deadlines consistently."`,
    color: "#E5FFFDCC",
  },
];

const CustomPrevArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <div
    {...props}
    style={{
      position: "absolute",
      left: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      background: "#004952",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    &lt;
  </div>
);

const CustomNextArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <div
    {...props}
    style={{
      position: "absolute",
      right: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      background: "#004952",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    &gt;
  </div>
);

const successPeople = [
  samMichalle,
  williamKart,
  stevenPark,
  laraClinton,
  matheshKumar,
];

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  centerMode: true,
  autoplay: true,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  focusOnChange: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  useCSS: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        fade: true,
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        fade: true,
        slidesToShow: 1,
      },
    },
  ],
};

const ITStaffAgency = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <section className={styles.itWrapper}>
        <img src={ITBanner} alt="Press Release" className={styles.image} />
        <div className={styles.overlay}>
          <div>
            <Text className={styles.text_1}>
              Hire our skilled professionals
            </Text>
            <br />
            <Text className={styles.text_2}>
              blend into your existing team for your products & service
            </Text>
          </div>
          <div className={styles.scrollToExplore}>
            <PiCaretDownBold />
            Scroll to explore
          </div>
        </div>
      </section>

      {/* Strengths */}
      <Content className={styles.strengths}>
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          justify="center"
          align="middle"
        >
          <div className={styles.itTexts}>
            <div>
              <Text className={styles.itTexts_1}>Our strengths</Text>
            </div>
            <Text className={styles.itTexts_2}>
              We have so many mega minds for you, Here is some samples for you
            </Text>
          </div>
          <Col md={2} lg={2}></Col>
          {teamData?.map((teamMember) => (
            <Col xs={24} sm={24} md={4} lg={4} key={teamMember}>
              <Image
                src={teamMember}
                preview={false}
                className={styles.image_card}
              />
            </Col>
          ))}
          <Col md={2} lg={2}></Col>
        </Row>
      </Content>

      <Content style={{ padding: "30px" }}>
        <Row style={{ position: "relative" }} gutter={isMobile ? [16, 16] : ""}>
          <div className={styles.itTexts}>
            <div style={{ paddingBottom: "9px" }}>
              <Text className={styles.itTexts_1}>Infinite on one cost</Text>
            </div>
            <div>
              <span className={styles.itTexts_2_1}>
                Hire our excellent team for your brighter future
              </span>
              <Text className={styles.itTexts_3}>
                Our well talented software developers, designers, testers and
                analysts can work and helps in your digital way.
              </Text>
            </div>
          </div>
          {hiringData?.map((data, id) => (
            <React.Fragment key={data?.title}>
              {id === 0 || id === 2 ? <Col md={1} lg={2}></Col> : ""}
              <Col xs={24} sm={24} md={11} lg={10}>
                <Card
                  className={`${styles.hiring_card} ${
                    isMobile ? styles.mobile : ""
                  }`}
                  style={{
                    backgroundColor: data?.color,
                    borderTopLeftRadius: id === 0 && !isMobile ? "10px" : "",
                    borderTopRightRadius: id === 1 && !isMobile ? "10px" : "",
                    borderBottomLeftRadius: id === 2 && !isMobile ? "10px" : "",
                    borderBottomRightRadius:
                      id === 3 && !isMobile ? "10px" : "",
                  }}
                >
                  <Text className={styles.card_title}>{data?.title}</Text>
                  <br />
                  <Text className={styles.card_text}>{data?.sub}</Text>
                </Card>
              </Col>
              {id === 1 || id === 3 ? <Col md={1} lg={2}></Col> : <></>}
            </React.Fragment>
          ))}
          <img
            src={card_center_image}
            alt="vector"
            className={styles.card_image}
          />
        </Row>
      </Content>

      <ContactForm />

      {/* Carousel */}
      <Slider {...settings} style={{ margin: "2%", overflowX: "hidden" }}>
        {successPeople?.map((person) => (
          <div key={person}>
            <Row gutter={16}>
              <Col>
                <Image
                  preview={false}
                  src={person}
                  alt="Carousel image"
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: "0 8px",
                  }}
                />
              </Col>
            </Row>
          </div>
        ))}
      </Slider>
      <ExcellenceAndSuccessCards />
      <Skills />
      <Insights />
    </>
  );
};

export { ITStaffAgency };

import React from "react";
import Slider from "react-slick";
import Slide from "./Components/Slide";
import { Layout, Typography } from "antd";
import stevenPark from "../Assets/testi3.png";
import williamKart from "../Assets/testi2.png";
import samMichalle from "../Assets/testi1.png";
import laraClinton from "../Assets/testi4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Styles/carousalClient.module.css";
const { Content } = Layout;
const { Text } = Typography;

const CustomPrevArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <div
    {...props}
    style={{
      position: "absolute",
      left: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      background: "#004952",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    &lt;
  </div>
);

const CustomNextArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <div
    {...props}
    style={{
      position: "absolute",
      right: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      background: "#004952",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    &gt;
  </div>
);

const successPeople = [
  {
    image: samMichalle,
    profession: "Sales lead, Growthscl, India.",
    name: "Sam Michalle",
    title:
      "I extend my heartfelt gratitude for creating an exceptional educational web app. Your innovation is an invaluable asset to our mission. Thanks to Pragmasoft!",
  },
  {
    name: "William Kart",
    profession: "CEO, Medihom, UK",
    image: williamKart,
    title:
      "Thank you for creating our groundbreaking medical e-commerce app. Your dedication and expertise have revolutionized our operations and patient care. Grateful for your outstanding partnership.",
  },
  {
    name: "Steven Park",
    profession: "CEO, Myjuk edtech, US",
    image: stevenPark,
    title:
      "Grateful to Pragmasoft for creating our innovative edtech website. Your dedication and expertise have transformed education for countless students. Thank you!",
  },
  {
    name: "Lara clinton",
    profession: "CEO,Women cart,Australia",
    image: laraClinton,
    title:
      "Thank you for crafting our stunning Women cart ecommerce website. Your team's talent and dedication have brought our vision to life. Exceptional work!",
  },
];

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  centerMode: true,
  autoplay: true,
  slidesToScroll: 1,
  autoplaySpeed: 2000,
  focusOnChange: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  useCSS: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        fade: true,
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        fade: true,
        slidesToShow: 1,
      },
    },
  ],
};

const CarousalClient = ({ title, about }) => {
  return (
    <>
      {/* Testimonials */}
      <Content className={styles.testimonialsTextWrapper}>
        <div className="titleWrapper">
          <Text className={styles.testimonialsTitle}>{title}</Text>
        </div>
        <Text className={styles.testimonialsAbout}>{about}</Text>
      </Content>
      <Slider
        {...settings}
        style={{
          margin: "2%",
          padding: "15px",
        }}
      >
        {successPeople?.map((person) => (
          <Slide key={person?.title} person={person} />
        ))}
      </Slider>
    </>
  );
};

export { CarousalClient };

import React from "react";
import { Button, Col, Image, Row, Typography } from "antd";
import questionsImage from "../../Assets/questions.png";
import styles from "../Styles/dataDriven_questions_PAR.module.css";
const { Title } = Typography;

const ReadyForQuestions = () => {
  return (
    <section className={styles.bg_color_1} style={{ overflowX: "hidden" }}>
      <Row justify="center">
        <Title className={styles.drivenByDataTitle}>
          We are ready for your questions
        </Title>
      </Row>
      <Row
        style={{ padding: "2rem 3rem 3.5rem 3rem" }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        justify="center"
      >
        <Col md={1} lg={1}></Col>
        <Col xs={24} sm={24} md={9} lg={9}>
          <Image
            src={questionsImage}
            preview={false}
            style={{ width: "100%" }}
          />
        </Col>
        <Col md={1} lg={1}></Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row style={{ height: "100%" }} align="middle">
            <Col span={24} className={styles.drivenByDataText}>
              <p>
                Meet our executives and subject matter experts & take away the
                insights you need to innovate & outperform your market with
                Informatica.
              </p>
              <Button className={styles.btn}>Visit Brief Center</Button>
            </Col>
          </Row>
        </Col>
        <Col md={1} lg={1}></Col>
      </Row>
    </section>
  );
};

export default ReadyForQuestions;

import React from 'react';
import { Col, Layout, Row } from 'antd';
import styles from '../Styles/services.module.css';
const { Content } = Layout;
const BasicCard = ({ cards }) => {
    return (
        <div style={{margin:"40px"}}>
            <Row gutter={[{xs:8,sm:16,md:24,lg:32},16]}>
                {cards?.map(card => (
                    <Col key={card?.content} xs={24} sm={12} md={12} lg={12} >
                        <Content className={`${styles.containerStyle}`}>
                            <img style={{ position: "relative" }} className={styles.image} src={card?.image} alt="company" />
                            <div className={`${styles.textStyle} ${styles.poppins} ${styles.basicCARD}`}>
                                {card?.title}
                                <span className={styles.basicCardText}>
                                    {card?.content}
                                </span>
                            </div>
                        </Content>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default BasicCard;

import React from "react";
import { Button, Layout } from "antd";
import successstories from "../Assets/successstories.png";
import { CarousalClient } from "../Carousal";
import CaseStudies from "../Common/Components/CaseStudies";
import styles from "./Styles/successStories.module.css";
const { Content } = Layout;

const SuccessStories = () => {
  return (
    <>
      <div style={{ fontWeight: 500 }}>
        <Content className={styles.containerStyle}>
          <img className={styles.image} src={successstories} alt="company" />
          <div className={styles.textStyle}>
            <p>Join with us & build the dots of future</p>
            <Button className={styles.joinWithUsBtn}>Join with us</Button>
          </div>
        </Content>
        <Content style={{ marginTop: "20px" }}>
          <div className={styles.successstoriesTitle}>
            Success stories from our beloved clients
          </div>
          <p style={{ fontWeight: 500, fontSize: "1.2em" }}>
            We built people with successful stories all over the world
          </p>
        </Content>
        <CarousalClient />
        <CaseStudies />
      </div>
    </>
  );
};

export { SuccessStories };

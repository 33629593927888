import icon1 from '../../Assets/mdi_run-fast.png';
import icon2 from '../../Assets/mdi_customer-service.png';
import icon3 from '../../Assets/direction_3173960 1.png';
import icon4 from '../../Assets/struggle_10488308 1.png';
import icon5 from '../../Assets/users-gear_9844441 1.png';
import icon6 from '../../Assets/mdi_hope.png';
import icon7 from '../../Assets/performance_1013386 (1) 1.png';
import icon8 from '../../Assets/hand-heart_10199178 1.png';
import webIcon from '../../Assets/websiteIcon.png';
import webappIcon from '../../Assets/WebAppIcon.png';
import mobileAppIcon from '../../Assets/mobileAppIcon.png';
import crmIcon from '../../Assets/CRMIcon.png';
export const PragmasoftData =[
    {
        image:icon1,
        title:"Going the Extra Mile",
        desc:"Good enough doesn’t even begin to cut it. We believe in exceeding customer expectations.",
        colour:"#FFE5E5"
    },
    {
        image:icon2,
        title:"Get into Customers Minds",
        desc:" We make it a point to understand our customer perspectives to exceed their expectations.",
        colour:"#FFFEE5"
    },
    {
        image:icon3,
        title:"Way of Life",
        desc:"A happy team is a productive team. Our people-first approach pays big dividends to our clients.",
        colour:"#EBFFE5"
    },
    {
        image:icon4,
        title:"Belief and Effort",
        desc:"We adhere to standards, and quality with the objective belief that we what we do is the best for our client.",
        colour:"#E5FFFD"
    },
    {
        image:icon5,
        title:"Teamwork All the Way",
        desc:"All for one and one for all, that’s how we roll. We collaborate & we criticize constructively.",
        colour:"#F5E5FF"
    },
    {
        image:icon6,
        title:"Staying Honest",
        desc:"Integrity is big on our list of values. We are always truthful and transparent to our clients and employees.",
        colour:"#E5F0FF"
    },
    {
        image:icon7,
        title:"Continuous improvement",
        desc:"We strive for continuous improvement in all aspects of our work, be it processes or tech.",
        colour:"#FFF5E5"
    },
    {
        image:icon8,
        title:"Treat People with Respect",
        desc:"We're an empowered bunch. Everyone has a right to their opinion and they can speak up without reprisal.",
        colour:"#EEEEEE"
    }
];

export const weDealWithData =[
    {
        image:webIcon,
        tag:"Website",
        desc:"Set of related web pages located under a single domain name, typically produced by a single person or organization."
    },
    {
        image:webappIcon,
        tag:"Web application",
        desc:"A web-application is an application program that is usually stored on a remote server, & users can access it through the use of Software known as web-browse."
    },
    {
        image:mobileAppIcon,
        tag:"Mobile application",
        desc:"A mobile application, most commonly referred to as an app, is a type of application software designed to run on a mobile device, such as a smartphone or tablet computer."
    },
    {
        image:crmIcon,
        tag:"Robust CRM",
        desc:"A robust CRM is one that weaves a total picture of people & their connections to organizations, grants, gifts, funds, loans, committees, events, and more."
    }
]
import React from "react";
import { Col, Row, Typography } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../Styles/carousalClient.module.css";
const { Text } = Typography;

const Slide = ({ person }) => {
  return (
    <div style={{ margin: "20px" }}>
      <div className={styles.successCard}>
        <Row>
          <Col xs={24} sm={10} style={{ position: "relative", zIndex: "5" }}>
            <img
              src={person?.image}
              alt="Your_image"
              style={{
                width: "100%",
                height: "auto",
                padding: 0,
                MozBorderRadiusTopleft: "7px",
                MozBorderRadiusBottomleft: "7px",
              }}
            />
            <div className={styles.person}>
              <Text className={styles.person_name}>{person?.name}</Text>
              <Text className={styles.person_profession}>
                {person?.profession}
              </Text>
            </div>
          </Col>
          <Col
            xs={24}
            sm={14}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text className={styles.person_about}>{person?.title}</Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Slide;

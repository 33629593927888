import React from "react";
import CareersCard from "./components/CareersCard";
import ResumeForm from "./components/ResumeForm";
import {Button, Layout, Space} from "antd";
import careers from "../Assets/careers.png";
import "react-phone-input-2/lib/style.css";
import styles from "./Styles/careers.module.css";

const { Content } = Layout;

const Careers = () => {
    const jobs = [
        {
            name: "IT",
            job: "UX/UI designer",
            experience: "0-1 year",
            location: "Remote",
            applyLink:"https://wellfound.com/jobs/2873056-ux-ui-designer"
        },
        {
            name:"IT",
            job:"Backend Developer",
            experience:"0-1 year",
            location:"Remote",
            applyLink:"https://wellfound.com/jobs/2873054-backend-developer"
        },
        {
            name: "IT",
            job: "Senior Mobile App Developer",
            experience: "2-3 years",
            location: "Remote",
            applyLink:"https://wellfound.com/jobs/2873053-senior-mobile-app-developer"
        },
        {
            name: "IT",
            job: "MERN stack Developer",
            experience: "2-3 years",
            location: "Remote",
            applyLink:"https://wellfound.com/jobs/2873050-fullstack-public-mern-stack-developer"
        }
    ];

    return (
        <Space direction="vertical">
            <Content className={styles.imageStyle}>
                <img src={careers} className={styles.image} alt="Careers" />
                <div className={styles.textStyle}>
                    <p>Join with us & build the dots of future</p>
                    <Button className={styles.Joinwithus}>Join with Us</Button>
                </div>
            </Content>
            <Content style={{ margin: "20px" }}>
                <p className={styles.careersTitle}>Careers</p>
                <p style={{ fontSize: "1.3em" }}>
                    If you have the passion to succeed, we have the opportunities to
                    ensure you thrive. So, be part of this dynamic ecosystem. Be part of
                    us.
                </p>
                <Content className={styles.flex}>
                    {jobs?.map((job) => (
                        <CareersCard key={job?.job} job={job}/>
                    ))}
                </Content>
            </Content>
            <Layout>
                <h1 style={{fontSize:"1.6em"}}>Post Your Resume / Registration </h1>
                <Content>
                    <ResumeForm/>
                </Content>
            </Layout>
        </Space>
    );
};

export { Careers };

import React from "react";
import { Col, Divider, Row, Space, Image, Typography } from "antd";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import Facebook from "../Assets/FB.png";
import Instagram from "../Assets/Insta.png";
import linkedin from "../Assets/linkedin.png";
import youtube from "../Assets/youtube.png";
import twitter from "../Assets/twitter.png";
import Logo from "../Assets/Logo.png";
import LogoSymbol from "../Assets/justLogo.png";
import logoStyles from "../App/Styles/App.module.css";
import styles from "./Styles/footer.module.css";
const { Text } = Typography;

const footerDetails = [
  {
    title: "Company",
    subSection: [
      {
        name: "About us",
        link: "#",
      },
      {
        name: "Our team",
        // link: "/ourteam",
        link: "#",
      },
      {
        name: "Success story",
        // link: "/successstories",
        link: "#",
      },
      {
        name: "Career",
        // link: "/careers",
        link: "#",
      },
      {
        name: "Blog",
        // link: "/blog",
        link: "#",
      },
      {
        name: "Press release",
        // link: "/pressrelease",
        link: "#",
      },
      {
        name: "Out sourcing",
        // link: "/outsourcing",
        link: "#",
      },
    ],
  },
  {
    title: "Who we serve",
    subSection: [
      {
        name: "Scaleups",
        link: "/scaleup",
      },
      {
        name: "Enterprises",
        link: "/enterprise",
      },
      {
        name: "Product/ SaaS firms",
        link: "SaaSfirms",
      },
      {
        name: "IT staff agency",
        link: "it_staff_agency",
      },
    ],
  },
  {
    title: "Services",
    subSection: [
      {
        name: "Generative AI",
        link: "/generative-AI",
      },
      {
        name: "Product development",
        link: "/productDevelopment",
      },
      {
        name: "Web development",
        link: "/webDevelopment",
      },
      {
        name: "App development",
        link: "/appDevelopment",
      },
      {
        name: "ERP & CRM implementation",
        link: "/erp-crm-implementation",
      },
      {
        name: "E-commerce development",
        link: "/ecommerce",
      },
      {
        name: "DevOps",
        link: "/devops",
      },
      {
        name: "Testing / QA",
        link: "/testing",
      },
    ],
  },
];

const socialAccounts = [
  {
    link: "https://www.linkedin.com/company/98836985/admin/feed/posts/",
    image: linkedin,
  },
  {
    link: "https://www.facebook.com/profile.php?id=61553704289627",
    image: Facebook,
  },
  {
    link: "https://www.instagram.com/pragma_soft_/",
    image: Instagram,
  },
  {
    link: "https://www.youtube.com/@PragmaSoft-bc8of",
    image: youtube,
  },
  {
    link: "https://twitter.com/home",
    image: twitter,
  },
];
console.log("data");
function FooterComp() {
  return (
    <footer id={styles.footer}>
      {/* First Row */}
      <Row align={"middle"}>
        <Col span={4} xs={24} md={4} className={styles.footer_column_1}>
          <Link to="/">
            <img src={Logo} alt="Logo" width={"100%"} />
          </Link>
        </Col>
        <Col span={20} xs={24} md={20} className={styles.footer_column_2}>
          <div align="center" className={styles.phoneEmail}>
            <div className={styles.email}>
              <MdEmail className={styles.emailImage} />
              contact@pragmasoft.in
            </div>
            <div className={styles.phone} align="center">
              <FaPhoneAlt className={styles.phoneImage} />
              (+91) 8483-089-257
            </div>
          </div>
        </Col>
      </Row>
      <Divider className={styles.antDivider} />

      {/* Second Row */}
      <Row className={styles.footer_row_2}>
        Founded in 2019, PragmaSoft is a India-based software company with CMMi
        3 standards providing a pre-vetted pool of top 3% software developers
        from our 10+ global locations. Partner with us and say "Hello" to
        Innovation.
      </Row>

      {/* Third Row */}
      <Row style={{ margin: "2rem 0" }} className={styles.footer_lg}>
        {footerDetails?.map((el) => (
          <Col
            key={el?.title}
            xs={{ span: 5, offset: 1 }}
            lg={{ span: 5, offset: 1 }}
          >
            <Space direction="vertical" className={styles.details_space}>
              <div className={styles.subFooterHeading}>{el?.title}</div>
              {el?.subSection?.map((list) => (
                <Link
                  key={list?.name}
                  to={list?.link}
                  disabled={list?.link === "#" ? true : false}
                  style={{ color: list?.link === "#" ? "gray" : "#d4d4d4" }}
                >
                  <span className={styles.listName}>{list?.name}</span>
                </Link>
              ))}
            </Space>
          </Col>
        ))}
        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
          <Space direction="vertical" className={styles.details_space}>
            <div className={styles.subFooterHeading}>Connect with us</div>
            <div className={styles.logoTextContainer}>
              <div className={styles.logoContainer}>
                <Link to="/">
                  <img src={LogoSymbol} alt="Logo" width={"100%"} />
                </Link>
              </div>
              {/* <div> */}
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  marginBottom: "10px",
                }}
                className={logoStyles.LogoAlignment}
              >
                PragmaSoft
              </div>

              <div className={styles.socials}>
                {socialAccounts?.map((el) => (
                  <a
                    key={el?.link}
                    href={el?.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Image preview={false} src={el?.image}></Image>
                  </a>
                ))}
              </div>
              {/* </div> */}
            </div>
          </Space>
        </Col>
      </Row>

      <Row style={{ margin: "2rem 0" }} className={styles.footer_md}>
        <div>
          <Text> Company </Text>
          <Text> Who we serve </Text>
          <Text> Services </Text>
        </div>

        <div>
          <Text>
            <Link to="/privacypolicy" style={{ color: "#fff" }}>
              Privacy Policy
            </Link>
          </Text>
          <Text>
            <Link to="/termsofservice" style={{ color: "#fff" }}>
              Terms of Service
            </Link>
          </Text>
          <Text>
            <Link to="/support" style={{ color: "#fff" }}>
              Support
            </Link>
          </Text>
        </div>
      </Row>

      <Divider className={styles.antDivider2} />

      {/* Fourth Row */}
      <Row style={{ margin: "2rem 0" }}>
        <div style={{ textAlign: "initial" }} className={styles.footer_bottom}>
          <div className={styles.footer_bottom__copyright}>
            <AiOutlineCopyrightCircle style={{ fontSize: "18px" }} /> 2023
            <Link to="/" style={{ color: "#fff" }}>
              PragmaSoft.in
            </Link>
          </div>

          <div className={styles.footer_bottom_pts}>
            <div>
              <Link to="/privacypolicy" style={{ color: "#fff" }}>
                Privacy Policy
              </Link>
            </div>
            |
            <div>
              <Link to="/termsofservice" style={{ color: "#fff" }}>
                Terms of Service
              </Link>
            </div>
            |
            <div>
              <Link to="/support" style={{ color: "#fff", zIndex: 15 }}>
                Support
              </Link>
            </div>
          </div>

          <div className={styles.socials2}>
            {socialAccounts?.map((el) => (
              <a
                key={el?.link}
                href={el?.link}
                rel="noreferrer"
                target="_blank"
              >
                <Image preview={false} src={el?.image}></Image>
              </a>
            ))}
          </div>
        </div>
      </Row>
    </footer>
  );
}
export { FooterComp };

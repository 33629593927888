import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import kindergarten from "../../Assets/Kindergarten.png";
import middleschool from "../../Assets/MiddleSchool.png";
import higherss from "../../Assets/HigherSS.png";
import companies from "../../Assets/Fintech1.png";
import banks from "../../Assets/Fintech2.png";
import service from "../../Assets/service.png";

const CommonModal = ({ solutionName }) => {
  const modalContents = {
    Edtech: {
      heading: "What is Edutech?",
      definition:
        "Educational technology (commonly abbreviated as edutech, or edtech) is the combined use of computer hardware, software, & educational theory & practice to facilitate learning.",
      content: [
        {
          heading: "Kindergarten",
          description:
            "E-learning kindergarten content has proved to be more effective & successful than traditional learning. It stimulates the kid’s learning especially when it is game-based learning. So we are expertise in making website & mobile application for kid learning products.",
          image: kindergarten,
        },
        {
          heading: "Middle school",
          description:
            "A middle school (Grade 1st to 8th) is an educational stage between primary school and secondary school. It stimulates the students learning especially when it is practical-based learning. we are expertise in making website & mobile application for middle school students learning products.",
          image: middleschool,
        },
        {
          heading: "Higher Secondary School",
          description:
            "Higher secondary school (9th to 12th) is an educational stage of high schooling. It stimulates the students learning especially when it is pratical-based learning. we are expertise in making website & mobile application for high school students learning products.",
          image: higherss,
        },
      ],
    },
    Fintech: {
      heading: "What is Fintech?",
      definition:
        "Financial technology websites include web pages of various companies, e.g. those developing new payment methods, providing investment and banking services, offering home budget management tools, and many others.",
      content: [
        {
          heading: "For companies",
          description:
            "Financial Management software is used for tracking and analyzing financial stability of organizations as well as making predictions about their future financial performance. We are expertise in making website & mobile application for finance managment products",
          image: companies,
        },
        {
          heading: "For banks",
          description:
            "E-Banking are often used by financial institutions such as bank and other parties in the banking industry we are expertise in making website & mobile application for banking fintech products.",
          image: banks,
        },
      ],
    },
    ServiceBasedCompanies: {
      heading: "What is Service Based?",
      definition:
        "A service-based company offers intangible products, expertise, or assistance to clients,focusing on delivering solutions & support rather than tangible goods.It thrives on providing quality services across various industries.We are providing valuable services for your products.Ex- Web development, App development, Design, User testing, DevOps.",
      content: [{ image: service }],
    },
  };
  const [selectedContent, setSelectedContent] = useState(
    modalContents[solutionName]
  );

  useEffect(() => {
    if (modalContents.hasOwnProperty(solutionName)) {
      setSelectedContent(modalContents[solutionName]);
    }
    // eslint-disable-next-line
  }, [solutionName]);

  return (
    <div style={{ textAlign: "center", fontWeight: "500" }}>
      <h1>{selectedContent?.heading}</h1>
      <div>{selectedContent?.definition}</div>
      <p style={{ fontSize: "1.2em", fontFamily: "'Poppins',sans-serif" }}>
        Categories we work on :
      </p>
      <Row gutter={[16, 16]}>
        {selectedContent?.content?.map((content) => (
          <Col
            key={content?.heading}
            xs={24}
            sm={24}
            md={
              selectedContent?.content?.length > 1
                ? 24 / selectedContent?.content?.length
                : 24
            }
            lg={
              selectedContent?.content?.length > 1
                ? 24 / selectedContent?.content?.length
                : 24
            }
            xl={
              selectedContent?.content?.length > 1
                ? 24 / selectedContent?.content?.length
                : 24
            }
            style={{ border: "1px dashed grey" }}
          >
            <h3 style={{ fontFamily: "'Poppins',sans-serif" }}>
              {content?.heading}
            </h3>
            <img
              src={content?.image}
              alt="Kindergarten"
              style={{ width: "50%" }}
            />
            <div style={{ fontFamily: "'Poppins',sans-serif" }}>
              {content?.description}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CommonModal;

import React from "react";
import { Typography } from "antd";
import PressReleaseImg from "../Assets/PressRelease/PressRelease.png";
import PressImage_1 from "../Assets/PressRelease/p1.png";
import PressImage_2 from "../Assets/PressRelease/p2.png";
import PressImage_3 from "../Assets/PressRelease/p3.png";
import PressImage_4 from "../Assets/PressRelease/p4.png";
import PressImage_5 from "../Assets/PressRelease/p5.png";
import styles from "./Styles/press.module.css";

const { Text } = Typography;

const pressReleaseData = [
  {
    title: "PragmaSoft Recognized as a Great Place to Work",
    photoData: PressImage_1,
    releaseDate: "November 8, 2023",
  },
  {
    title: "Continued demand drives strong growth for PragmaSoft",
    photoData: PressImage_2,
    releaseDate: "November 6, 2023",
  },
  {
    title:
      "Mahesh Appointed Chairperson of Unique Identification Authority of India(UIDAI).",
    photoData: PressImage_3,
    releaseDate: "November 5, 2023",
  },
  {
    title: "PragmaSoft Receives CISF Cover At Its Pune Campus...",
    photoData: PressImage_4,
    releaseDate: "November 4, 2023",
  },
  {
    title:
      "How to build robust startup ecosystem in Tricity : Mahesh, Co-founder and CEO, PragmaSoft",
    photoData: PressImage_5,
    releaseDate: "November 2, 2023",
  },
];

const PressRelease = () => {
  return (
    <>
      <section>
        <img
          src={PressReleaseImg}
          alt="Press Release"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <div className={styles.text}>Press Release</div>
          <div className={styles.text}>Get all news here about us</div>
        </div>
      </section>
      <section className={styles.pressReleaseContent}>
        <div className={styles.pressReleaseContent__title}>Top Results</div>
        <div className={styles.pressReleaseContainer}>
          {pressReleaseData?.map((el) => (
            <div key={el?.title} className={styles.pressReleaseCard}>
              <div className={styles.imageContainer}>
                <img src={el?.photoData} alt="Image1" />
              </div>
              <div className={styles.pressReleaseCardText}>
                <Text className={styles.pressReleaseCardText__1}>
                  {el?.title}
                </Text>
                <Text className={styles.pressReleaseCardText__2}>
                  Press release | {el?.releaseDate}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export { PressRelease };

import React from 'react';
import styles from '../Styles/scaleup.module.css';

const DealWithDataCard = ({data}) => {
  return (
    <div className={styles.weDealWithCard}>
    <div className={styles.weDealWithIcon}>
        <img style={{width:"25px"}} src={data?.image} alt='Website Icon' />
        <div style={{fontSize:"0.8em"}}>{data?.tag}</div>
    </div>
    <div>
        <p>{data?.desc}</p>
    </div>
</div>
  )
}

export default DealWithDataCard;
import React from "react";
import { Card, Col, Row, Typography } from "antd";
import styles from "../Styles/services.module.css";
const { Text } = Typography;

const DevelopmentCard = ({ stages }) => {
  return (
    <>
      <Row
        gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        style={{ justifyContent: "center" }}
      >
        {stages?.map((stage) => {
          return (
            <Col
              key={stage?.desc}
              xs={24}
              sm={12}
              md={12}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Row style={{ position: "relative" }}>
                <Card
                  hoverable
                  style={{
                    width: 280,
                    height: 420,
                    border: "0.5px solid lightgrey",
                  }}
                  cover={<img alt="development" src={stage?.image} />}
                >
                  <Text
                    style={{
                      color: "#335255",
                      fontFamily: "Poppins,sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    {stage?.desc}
                  </Text>
                  <Text className={styles.Developmenttext}>{stage?.title}</Text>
                </Card>
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default DevelopmentCard;

import React from "react";
import { Typography, Button, Row, Col } from "antd";

import OurTeamBanner from "../Assets/OurTeam/banner.png";
import Team_member_1 from "../Assets/OurTeam/1.png";
import Team_member_2 from "../Assets/OurTeam/2.png";
import Team_member_3 from "../Assets/OurTeam/3.png";
import Team_member_4 from "../Assets/OurTeam/4.png";
import Team_member_5 from "../Assets/OurTeam/5.png";
import Team_member_6 from "../Assets/OurTeam/6.png";
import Team_member_7 from "../Assets/OurTeam/7.png";
import Team_member_8 from "../Assets/OurTeam/8.png";
import Team_member_9 from "../Assets/OurTeam/9.png";
import Team_member_10 from "../Assets/OurTeam/10.png";
import Team_member_11 from "../Assets/OurTeam/11.png";
import Team_member_12 from "../Assets/OurTeam/12.png";
import Team_member_13 from "../Assets/OurTeam/13.png";
import Team_member_14 from "../Assets/OurTeam/14.png";
import styles from "./Styles/ourTeam.module.css";
const { Text, Title } = Typography;

const TeamHr = [
  { name: "Rohit", title: "Senior Human Resource", image: Team_member_3 },
  { name: "Sahana", title: "HR & IT Recruiter", image: Team_member_4 },
];

const TeamDevelopers = [
  { name: "Naveen", title: "Senior Wev Developer", image: Team_member_5 },
  { name: "Rafeeth khan", title: "Wev Developer", image: Team_member_6 },
  { name: "Anisha", title: "Wev Developer", image: Team_member_7 },
  { name: "Rohit", title: "Senior Android Developer", image: Team_member_8 },
  { name: "Banu Shree", title: "Android Developer", image: Team_member_9 },
];

const TeamDesigners = [
  { name: "Arjun", title: "UX/UI Designer", image: Team_member_10 },
  { name: "Banu Shree", title: "Android Developer", image: Team_member_11 },
];

const TeamTesting = [
  { name: "Arsath", title: "Software Tester", image: Team_member_12 },
  { name: "Shruthi", title: "Software Tester", image: Team_member_13 },
  { name: "Megana", title: "Software Tester", image: Team_member_14 },
];

const OurTeam = () => {
  return (
    <>
      <section className={styles.section_1}>
        <img src={OurTeamBanner} alt="Support" className={styles.image} />
        <div className={styles.overlay}>
          <Text className={styles.text}>Team is Power: Trust: Everything</Text>
          <Button className={styles.btn}>Meet Our Team</Button>
        </div>
      </section>
      <Row
        className={`${styles.sectionDetails}, ${styles.color_1}`}
        style={{ padding: "30px" }}
      >
        <Col span={24} className={styles.columnDetails}>
          <div className={styles.ourTeamContainer}>
            <img
              src={Team_member_1}
              alt="Team 1"
              className={styles.ourTeamImage}
            />
          </div>
          <Title className={styles.title}>Amit Walia</Title>
          <Text className={styles.about}>
            Founder and CEO of PragmaSoft,
            <br />
          </Text>
          <Text className={styles.about}>
            and a First Generation Serial Entrepreneur, responsible for creating
            a footprint of global excellence in the IT domain.
          </Text>
        </Col>
      </Row>
      <Row
        className={`${styles.sectionDetails}, ${styles.color_2}`}
        style={{ padding: "30px" }}
      >
        <Col span={24} className={styles.columnDetails}>
          <div className={styles.ourTeamContainer}>
            <img
              src={Team_member_2}
              alt="Team 2"
              className={styles.ourTeamImage}
            />
          </div>
          <Title className={styles.title}>Mahesh</Title>
          <Text className={styles.about}>
            Co-Founder and CEO of PragmaSoft,
            <br />
          </Text>
          <Text className={styles.about}>
            & a First Generation Serial Entrepreneur, responsible for creating a
            footprint of global excellence in the IT domain.
          </Text>
        </Col>
      </Row>

      {/* TEAM HR */}
      <section
        className={`${styles.sectionDetails}, ${styles.color_1}`}
        style={{ padding: "30px" }}
      >
        <Row className={styles.heading}>
          <Text className={styles.headingText}>Team HR</Text>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {TeamHr?.map((el) => (
            <Col key={el?.name} span={12} className={styles.columnDetails}>
              <div className={styles.ourTeamContainer}>
                <img
                  src={el?.image}
                  alt="Team 2"
                  className={styles.ourTeamImage}
                />
              </div>
              <Title className={styles.title}>{el?.name}</Title>
              <Text className={styles.about}>{el?.title}</Text>
            </Col>
          ))}
        </Row>
      </section>

      {/* TEAM DEVELOPERS */}
      <section
        className={`${styles.sectionDetails}, ${styles.color_2}`}
        style={{ padding: "30px" }}
      >
        <Row className={styles.heading}>
          <Text className={styles.headingText}>Team Developers</Text>
        </Row>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ justifyContent: "center" }}
        >
          {TeamDevelopers?.map((el) => (
            <Col key={el?.name} span={8} className={styles.columnDetails}>
              <div className={styles.ourTeamContainer}>
                <img
                  src={el?.image}
                  alt="Team 2"
                  className={styles.ourTeamImage}
                />
              </div>
              <Title className={styles.title}>{el?.name}</Title>
              <Text className={styles.about}>{el?.title}</Text>
            </Col>
          ))}
        </Row>
      </section>

      {/* TEAM DESIGNERS */}
      <section
        className={`${styles.sectionDetails}, ${styles.color_1}`}
        style={{ padding: "30px 0" }}
      >
        <Row className={styles.heading}>
          <Text className={styles.headingText}>Team Designers</Text>
        </Row>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ justifyContent: "center" }}
        >
          {TeamDesigners?.map((el) => (
            <Col key={el?.name} span={12} className={styles.columnDetails}>
              <div className={styles.ourTeamContainer}>
                <img
                  src={el?.image}
                  alt="Team 2"
                  className={styles.ourTeamImage}
                />
              </div>
              <Title className={styles.title}>{el?.name}</Title>
              <Text className={styles.about}>{el?.title}</Text>
            </Col>
          ))}
        </Row>
      </section>

      {/* TEAM TESTING */}
      <section
        className={`${styles.sectionDetails}, ${styles.color_2}`}
        style={{ padding: "30px" }}
      >
        <Row className={styles.heading}>
          <Text className={styles.headingText}>Team Testing</Text>
        </Row>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ justifyContent: "center" }}
        >
          {TeamTesting?.map((el) => (
            <Col key={el?.name} span={8} className={styles.columnDetails}>
              <div className={styles.ourTeamContainer}>
                <img
                  src={el?.image}
                  alt="Team 2"
                  className={styles.ourTeamImage}
                />
              </div>
              <Title className={styles.title}>{el?.name}</Title>
              <Text className={styles.about}>{el?.title}</Text>
            </Col>
          ))}
        </Row>
      </section>
    </>
  );
};

export default OurTeam;

import React from "react";
import { Typography } from "antd";
import SupportImg from "../Assets/Support.png";
import styles from "./Styles/support.module.css";

const supportData = [
  {
    title: "Knowledge Base",
    description:
      "Explore our comprehensive Knowledge Base for detailed articles, guides, and tutorials that cover a wide range of topics. Whether you're a new user or an experienced one, you'll find valuable insights to make the most out of [Your Product/Service].",
  },
  {
    title: "Frequently Asked Questions (FAQs)",
    description:
      "Browse through our frequently asked questions to quickly find answers to common queries. Our FAQs cover everything from account setup to troubleshooting common issues.",
  },
  {
    title: "Contact Us",
    description:
      "If you can't find the information you need in our Knowledge Base or FAQs, our dedicated support team is ready to assist you. Reach out to us via email, phone, or live chat. We aim to respond promptly & resolve your queries efficiently.",
  },
  {
    title: "Product/Service Updates",
    description:
      "Stay informed about the latest updates, features, and enhancements to [Your Product/Service]. Our update announcements provide valuable information on how to leverage new functionalities & improvements.",
  },
  {
    title: "Community Forums",
    description:
      "Connect with other [Your Product/Service] users in our community forums.  Share your experiences, learn from others, and get tips and tricks to optimize your use of our products/services.",
  },
  {
    title: "Troubleshooting Guides",
    description:
      "Encountering issues? Our troubleshooting guides walk you through common problems step by step, helping you resolve issues quickly and efficiently.",
  },
  {
    title: "Customer Feedback",
    description:
      "We value your feedback! Share your thoughts, suggestions, and ideas with us. Your input helps us improve our products and services to better meet your needs.",
  },
];

const Support = () => {
  return (
    <>
      <section>
        <img src={SupportImg} alt="Support" className={styles.image} />
        <div className={styles.overlay}>
          <Typography.Text className={styles.text}>Support</Typography.Text>
        </div>
      </section>
      <section className={styles.supportText}>
        <div className={styles.supportText_title}>
          Welcome to PragmaSoft Support Center
        </div>
        <ul>
          <li>
            At PragmaSoft, we are committed to providing you with exceptional
            support to enhance your experience with our products/services.
          </li>
          <li>
            Our Support Center is designed to assist you in finding answers to
            common questions, troubleshooting issues, & connecting with our
            support team.
          </li>
        </ul>

        {supportData?.map((support) => (
          <>
            <div className={styles.supportText_title}>{support?.title}</div>
            <div className={styles.description}>{support?.description}</div>
          </>
        ))}
      </section>
    </>
  );
};

export { Support };

import React from 'react';
import { Button, Card, Col, Image, Layout, Row } from 'antd';
import productBanner from '../Assets/ProductIT/productBanner.png';
import icon1 from '../Assets/ProductIT/hands.png';
import icon2 from '../Assets/ProductIT/ideasIcon.png';
import icon3 from '../Assets/ProductIT/laptopIcon.png';
import icon4 from '../Assets/ProductIT/roadmapIcon.png';
import research from '../Assets/ProductIT/research.png';
import development from '../Assets/ProductIT/development.png';
import support from '../Assets/ProductIT/support.png';
import team from '../Assets/ProductIT/team.png';
import styles from './Styles/productSaas.module.css';
import { ContactForm } from '../ContactForm/ContactForm';
import { Insights, Skills } from '../Insights';
const { Content } = Layout;

const ProductSaas = () => {
    const letUsKnowData = [
        {
            label: "5,000",
            title: "Active customers",
            sup: "+"
        },
        {
            label: "85",
            title: "of the fortune 100 trust us",
            sup: ""
        },
        {
            label: "54",
            title: "trillion clod transactions/mo",
            sup: "+"
        },
        {
            label: "3",
            title: "in house products",
            sup: "+"
        },
    ];
    const iconsInfo = [
        {
            image: icon4,
            title: "Product Roadmaps",
            desc: "Vision, direction, priorities & milestones"
        },
        {
            image: icon3,
            title: "Platform Engineering",
            desc: "Technology to innovate, design & develop"
        },
        {
            image: icon2,
            title: "Innovation focused solutions",
            desc: "Modernize product, crafted to facilitate faster time to market."
        },
        {
            image: icon1,
            title: "Support",
            desc: "Improve experience & maintain seamless bug free performance"
        }
    ]
    const products = [
        {
            image: research,
            content: "From product scratch to end, will do research & start to design."
        },
        {
            image: development,
            content: "Our well talented developers will create your product software side."
        },
        {
            image: support,
            content: "Maintain the whole product, which helps user on their comfort architecture."
        }
    ]
    return (
        <div style={{overflow:"hidden"}}>
            <Content className={styles.containerStyle}>
                <img
                    className={styles.image}
                    src={productBanner}
                    alt="product banner"
                />
                <div className={styles.textStyle}>
                    <div>Your product, our development</div>
                    <div>Your End to End services to take you next level</div>
                </div>
            </Content>
            <div className={styles.iconsContainer} style={{ padding: "40px", background: "#F2F2F2" }}>
                <div style={{ fontSize: "1.5em", fontFamily: "'Poppins',sans-serif" }}>Cover to Cover product development</div>
                <div style={{ fontSize: "1.2em", fontFamily: "'Poppins',sans-serif" }}>Design-Develop-Launch-Iterate your SaaS Product</div>
            </div>
            {/* icons */}
            <div className={styles.icons}>
                <Row gutter={[{ xs: 16, sm: 80, md: 0, lg: 0 }, 16]}>
                    {iconsInfo?.map(icon => (
                        <Col span={6} key={icon?.desc}>
                            <div className={`${styles.iconwithtext}`}>
                                {/* <div className={styles.iconContainer}> */}
                                    <img className={styles.icon} src={icon?.image} alt='Roadmap Icon' />
                                    <div className={styles.tooltip}>
                                        <div className={styles.bold}>{icon?.title}</div>
                                        <div className={styles.desc}>{icon?.desc}</div>
                                    </div>
                                    {/* <div className={styles.iconText}>{icon?.title}</div> */}
                                {/* </div> */}
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            {/* own your own product */}
            <div style={{ fontSize: "1.6em", textDecoration: "underline", fontFamily: "'Poppins',sans-serif" }}>Own Your Own Product</div>
            <h3 style={{ fontFamily: "'Poppins',sans-serif" }}>Stages we are following to build your product</h3>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} style={{ justifyContent: 'center' }}>
                {products?.map(product => (
                    <Col key={product?.content}>
                        <Card
                            hoverable
                            style={{ width: "250px", color: "black" }}
                            cover={<img alt="image1" src={product?.image} />}
                            className={`${styles.card}`}
                        >
                            <div>{product?.content}</div>
                        </Card>
                    </Col>
                ))}
            </Row>
            {/* team image with cards */}
            <Content style={{ margin: "20px 0",position:"relative" }}>
                <Image src={team} alt='team' preview={false} />
                <Row style={{ textAlign: "center", transform: "translate(0,-30%)" }}>
                    {letUsKnowData?.map(data => (
                        <Col key={data?.title} md={6} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Card hoverable className={styles.letUsknowcard}>
                                <div className={`${styles.bold} ${styles.label}`}>{data?.label}<sup className={`${styles.power}`}>{data?.sup}</sup></div><br /><span className={styles.title}>{data?.title}</span>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Button className={styles.getStartedBtn}>Get Started</Button>

            </Content>
            <ContactForm />
            <p style={{ fontSize: "1.8em", fontFamily: "Poppins,sans-serif" }}>One year of excellence & success</p>
            <Skills />
            <Insights />
        </div>
    )
}

export { ProductSaas };

import React from "react";
import { Button, Col, Image, Layout, Row, Typography } from "antd";
import { FaCaretRight } from "react-icons/fa";
import AboutUsBanner from "../Assets/aboutus_banner.png";
import ceoImage from "./utils/CEO.png";
import { useMediaQuery } from "react-responsive";
import { ContactForm } from "../ContactForm";
import styles from "./Styles/aboutUs.module.css";
import { CarousalClient } from "../Carousal";
import MobileCarousel from "../Home/Components/MobileCarousel";
import DynamicCurousal from "../Home/Components/DynamicCurousal";
const { Title, Text } = Typography;
const { Content } = Layout;

const aboutUsDetails = [
  {
    score: "5,000",
    description: "Active customers",
  },
  {
    score: "85",
    description: "of the fortune 100 trust us",
  },
  {
    score: "54",
    description: "trillion cloud transactions/mo",
  },
];

const AboutUs = () => {
  const isTab_Mobile = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 992 });

  return (
    <>
      <section className={styles.aboutus_wrapper}>
        <Content style={{ padding: "0", overflowX: "hidden" }}>
          {isMobile ? <MobileCarousel /> : <DynamicCurousal />}
        </Content>
        {/* About Pragmasoft */}
        <Row style={{ padding: "40px" }}>
          <Col span={24}>
            <Title className={styles.aboutus_title}>At Pragmasoft,</Title>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={2}></Col>
              <Col span={20}>
                <Text className={styles.aboutus_paragraph}>
                  we constantly push the boundaries of what’s possible by
                  leveraging our expertise, experience, and innovative ecosystem
                  to empower enterprises, people, and communities to build a
                  better Future, Faster, Together. Our commitment is to a
                  singular goal: to relentlessly ensure our clients become their
                  future sustainable selves ahead of schedule.
                </Text>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Col>
        </Row>

        {/* Contributions */}
        <Content style={{ overflowX: "hidden" }}>
          <Row
            style={{
              padding: "40px",
              backgroundColor: "#F3FEFF",
            }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col md={1} lg={1}></Col>
            {aboutUsDetails?.map((aboutData, index) => (
              <Col
                xs={24}
                sm={24}
                md={{ span: 6, offset: 1 }}
                lg={{ span: 6, offset: 1 }}
                key={aboutData?.description}
                style={{ marginBottom: isTab_Mobile ? "40px" : "" }}
                className={styles.aboutUsDetailsCard}
              >
                <div>
                  <div className={styles.aboutus_score}>
                    {aboutData?.score}
                    {index === 0 || index === 2 ? <sup>+</sup> : ""}
                  </div>
                  <div className={styles.divider}>
                    <hr className={styles.line} />
                    <FaCaretRight className={styles.caretIcon} />
                  </div>
                  <div className={styles.aboutus_descriptionWrap}>
                    <Text className={styles.aboutus_description}>
                      {aboutData?.description}
                    </Text>
                  </div>
                </div>
              </Col>
            ))}
            <Col md={1} lg={1}></Col>
          </Row>
        </Content>

        {/* Banner */}
        <Row>
          <section className={styles.aboutUsBanner}>
            <img src={AboutUsBanner} alt="About us" className={styles.image} />
            <div className={styles.overlay}>
              <Text className={styles.text_1}>What we believe in</Text>
              <Text className={styles.text_2}>
                We still believe in some old-fashioned values, in doing what is
                right. Our Values and Culture dictate how we behave and how we
                make our decisions. Our values are the blueprint by which we
                live our lives.
              </Text>
            </div>
          </section>
        </Row>

        {/* CEO */}
        <section
          style={{
            backgroundColor: "#d0dedf66",
            paddingBottom: "2rem",
            overflowX: "hidden",
          }}
        >
          <Row justify="center">
            <Title className={`${styles.title}`}>Leadership</Title>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              xs={{ offset: 2, span: 20 }}
              md={{ offset: 2, span: 10 }}
              lg={{ span: 10 }}
              order={isTab_Mobile ? 1 : 3}
            >
              <Image
                preview={false}
                src={ceoImage}
                className={styles.dataimagestyle}
                alt="CEO"
              />
              <h2
                style={{ marginBottom: 0, marginTop: "12px" }}
                className={styles.text}
              >
                Amit Walia
              </h2>
              <p style={{ marginTop: 0 }} className={styles.ceo}>
                CEO, PragmaSoft
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 9 }}
              lg={{ span: 9 }}
              className={`${styles.flex}`}
              order={isTab_Mobile ? 2 : 2}
            >
              <p className={styles.text}>
                “We are privileged to have a great customer base, an amazing
                team of employees across the globe, & a unique leadership
                position in all the markets where we compete.”
              </p>
              <Button className={`${styles.btn}`}>Meet our leaders</Button>
            </Col>
            <Col md={2} lg={2} order={1}></Col>
          </Row>
        </section>
      </section>

      {/* Testimonials */}
      <CarousalClient
        title={"Testimonials"}
        about={"We built people with successful stories all over the world"}
      />

      {/* Contact Form */}
      <Row>
        <ContactForm />
      </Row>
    </>
  );
};

export { AboutUs };

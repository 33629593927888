import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./Common/Components/ScrollToTop";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      components: {
        Menu: {
          horizontalItemSelectedColor: "none",
          itemSelectedColor: "#031C1F",
        },
      },
    }}
  >
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </ConfigProvider>
);

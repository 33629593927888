import React from "react";
import {letUsKnowData} from '../Common/Components/CommonData';
import styles from "./Styles/excellenceandSuccess.module.css";
import { Card,Layout } from "antd";
const {Content} =Layout;

const ExcellenceAndSuccessCards = () => {
  return (
    <Content className={styles.flex}>
      {letUsKnowData?.map((data) => (
        <Card key={data?.title} className={styles.letusknowcard}>
          <p className={styles.cardLabel}>
            {data?.label}
            <sup>{data?.sup}</sup>
          </p>
          <p className={styles.cardTitle}>{data?.title}</p>
        </Card>
      ))}
    </Content>
  );
};

export { ExcellenceAndSuccessCards };

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuOptions } from "../Common/Components/CommonData";
import { Layout, Menu } from "antd";
import darklogo from '../Assets/logo dark svg.svg';
import styles from "./Styles/header.module.css";
import logoStyles from './Styles/logo.module.css';
const { Header } = Layout;
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
    const currentPath = location.pathname.startsWith('/') ? location.pathname.substring(1) : location.pathname;
  return (
    <>
      <Header className={styles.headerStyle}>
        <div className={logoStyles.LogoAlignment}>
          <Link to="/">
            <img className={logoStyles.logoStyle}
              width={"100%"}
              src={darklogo}
              alt="Logo"
            />
          </Link>
        </div>
        <Menu  theme="light" mode="horizontal" onClick={(e) => navigate(e.key)} className={logoStyles.menuStyle} selectedKeys={[currentPath]} items={menuOptions} />
      </Header >

    </>
  );
}
export { Navbar };
import React, { useState } from 'react';
import { ContactForm } from '../ContactForm/ContactForm';
import { ExcellenceAndSuccessCards, Insights,Skills} from '../Insights';
import CommonModal from './Components/CommonModal';
import { Row, Col, Layout, Button, Modal } from 'antd';
import EnterpriseImg from '../Assets/Enterprise.png';
import developmentIcon from '../Assets/configuration.png';
import performanceIcon from '../Assets/united.png';
import fulfilledIcon from '../Assets/download.png';
import edtech from '../Assets/edtech.png';
import fintech from '../Assets/fintech.png';
import serviceBasedCompanies from '../Assets/serviceBasedCompanies.png';
import edtechIcon from '../Assets/edutechIcon.png';
import fintechIcon from '../Assets/fintechIcon.png';
import servicebasedIcon from '../Assets/serviceBasedCompanyIcon.png';
import styles from './Styles/enterprice.module.css';
const { Content } = Layout;

const Enterprices = () => {

    const solutions = [
        { name: "Edtech", image: edtech, icon: edtechIcon },
        { name: "Fintech", image: fintech, icon: fintechIcon },
        { name: "ServiceBasedCompanies", image: serviceBasedCompanies, icon: servicebasedIcon },
    ];
    const [solutionName, setSolutionName] = useState(solutions[0]?.name);
    const [img, setImg] = useState(solutions[0]?.image);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
      };

    const handleClick = (name) => {
        for (let i = 0; i < solutions.length; i++) {
            if (name === solutions[i].name) {
                setSolutionName(solutions[i].name);
                setImg(solutions[i].image);
                break;
            }
        }
    }

    return (
        <div>
            <Content className={styles.imageStyle}>
                <img src={EnterpriseImg} className={styles.image} alt="Scale up" />
                <div className={styles.textStyle}>
                    <div style={{ fontSize: "1.5em" }}>Enterprises need Experienced Experts.</div>
                    <p style={{ fontSize: "1.1em" }}>Serving Enterprises is a game of seasoned players who have infrastructure, scale, team & processes to deliver. We've got that all covered.</p>
                </div>
            </Content>
            <div className={styles.iconsContainer} style={{ padding: "30px", background: "#F2F2F2" }}>
                <div style={{ fontSize: "1.5em",fontFamily:"'Poppins',sans-serif"}}> Enterprised with streamlined process</div>
            </div>
            <div className={styles.icons}>
                <div>
                    <img className={styles.firstIcon} src={developmentIcon} alt='Development Icon' />
                    <div className={styles.bold}>Development Service</div>
                </div>
                <div>
                    <img className={styles.icon} src={performanceIcon} alt='Performance Icon' />
                    <div className={styles.bold}>High performing Team Work</div>
                </div>
                <div>
                    <img className={styles.icon} src={fulfilledIcon} alt='Fulfilled Icon' />
                    <div className={styles.bold}>Fulfilled outputs</div>
                </div>
            </div>
            <Content style={{ margin: "0 20px" }}>
                <p style={{ textDecoration: "underline", fontSize: "1.8em",fontFamily:"'Poppins',sans-serif" }}>We provide solution for</p>
                <Row gutter={[16,16]}>
                    <Col xs={24} sm={24} md={{offset:2,span:10}} lg={{offset:5,span:7}} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                        <div style={{display:"flex",flexDirection:"column",alignItems:"start",justifyContent:"center"}}>
                        {solutions?.map(solution => (
                            <div key={solution?.name} className={styles.flex} onClick={() => handleClick(solution?.name)}>
                                <img src={solution?.icon} alt={solution?.name} className={styles.solutionIcon} />
                                <div style={{fontSize: "1.3em", fontWeight: `${solutionName === solution?.name ? `bolder` : `500`}`, color: `${solutionName === solution?.name ? `#3A585B` : `black`}`,padding:"5px",fontFamily:"'Poppins',sans-serif" }}>{solution?.name}</div>
                            </div>
                        ))}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={{span:10,offset:0}} lg={{span:7,offset:0}}>
                        <img className={styles.imageStyle} style={{ width: "20em", height: "20em",cursor:"pointer" }} src={img} alt='Solution'  onClick={showModal} />
                        <div className={styles.techButton}>
                            <Button className={styles.moreInfo}  onClick={showModal}>Click for more Info</Button>
                            <Modal width={1000} footer={null} open={isModalOpen} onCancel={handleCancel} style={{top:"10vh"}}>
                                <CommonModal solutionName={solutionName}/>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Content>
            <ContactForm/>
            <p style={{fontSize:"1.8em"}}>One year of excellence & success</p>
            <ExcellenceAndSuccessCards/>
            <Skills/>
            <Insights/>
        </div>
    )
}

export { Enterprices };

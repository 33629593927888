import { Route, Routes } from "react-router-dom";
import { FloatButton, Layout } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { FooterComp } from "../Footer";
import { Navbar } from "../Navbar";
import { Support } from "../Static";
import { TermsofService } from "../Static";
import { PrivacyPolicy } from "../Static";
import { Home } from "../Home";
import { Careers } from "../Careers";
import { AboutUs, Outsourcing, PressRelease, SuccessStories } from "../Company";
import styles from "./Styles/App.module.css";
import { Enterprices, ProductSaas, ScaleUp } from "../WhoWeAre";
import { Blog } from "../Company/Blog";
import OurTeam from "../Company/OurTeam";
import { ITStaffAgency } from "../WhoWeAre/ITStaffAgency";
import { WebDvelopment } from "../Services/WebDvelopment";
import {
  AppDevelopment,
  DevOps,
  Ecommerce,
  ErpCrmImplementation,
  GenerativeAI,
  ProductDevelopment,
  Testing,
} from "../Services";

const { Footer, Content } = Layout;

function App() {
  const customIcon = <UpOutlined className={styles.backToTopIcon} />;
  return (
    <Layout className={styles.layoutStyle}>
      <Navbar />
      <Content className={styles.contentStyle}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/pressrelease" element={<PressRelease />} />
          <Route exact path="/successstories" element={<SuccessStories />} />
          <Route exact path="/termsofservice" element={<TermsofService />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/scaleup" element={<ScaleUp />} />
          <Route exact path="/enterprise" element={<Enterprices />} />
          <Route exact path="/ourteam" element={<OurTeam />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/outsourcing" element={<Outsourcing />} />
          <Route exact path="/it_staff_agency" element={<ITStaffAgency />} />
          <Route exact path="/SaaSfirms" element={<ProductSaas />} />
          <Route exact path="/webDevelopment" element={<WebDvelopment />} />
          <Route exact path="/appDevelopment" element={<AppDevelopment />} />
          <Route
            exact
            path="/productDevelopment"
            element={<ProductDevelopment />}
          />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/SaaSfirms" element={<ProductSaas />} />
          <Route exact path="/devops" element={<DevOps />} />
          <Route
            exact
            path="/erp-crm-implementation"
            element={<ErpCrmImplementation />}
          />
          <Route exact path="/generative-AI" element={<GenerativeAI />} />
          <Route exact path="/ecommerce" element={<Ecommerce />} />
          <Route exact path="/testing" element={<Testing />} />
        </Routes>
      </Content>
      <div className={styles.backto}>
        <FloatButton.BackTop
          shape="circle"
          icon={customIcon}
          rootClassName={styles.backtotop}
          style={{ backgroundColor: "#63005f" }}
        ></FloatButton.BackTop>
      </div>
      <Footer className={styles.footerStyle}>
        <FooterComp />
      </Footer>
    </Layout>
  );
}

export { App };

import React from "react";
import { Button, Col, Row, Typography } from "antd";
import ceoImage from "../utils/CEO.png";
import styles from "../Styles/home.module.css";
const { Title } = Typography;

const Providings = () => {
  return (
    <section
      style={{
        backgroundColor: "#d0dedf66",
        paddingBottom: "2rem",
        overflowX: "hidden",
      }}
    >
      <Row justify="center">
        <Title className={`${styles.title}`}>Leadership</Title>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 10 }}
          lg={{ span: 10 }}
        >
          <img src={ceoImage} className={styles.dataimagestyle} alt="CEO" />
          <h2
            style={{ marginBottom: 0, marginTop: "12px" }}
            className={styles.text}
          >
            Amit Walia
          </h2>
          <p style={{ marginTop: 0 }} className={styles.ceo}>
            CEO, PragmaSoft
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 9 }}
          lg={{ span: 9 }}
          className={`${styles.flex}`}
        >
          <p className={styles.text}>
            “We are privileged to have a great customer base, an amazing team of
            employees across the globe, & a unique leadership position in all
            the markets where we compete.”
          </p>
          <Button className={`${styles.btn}`}>Meet our leaders</Button>
        </Col>
        <Col md={2} lg={2}></Col>
      </Row>
    </section>
  );
};

export default Providings;

import React from "react";
import teamimg from "../utils/Team.png";
import styles from "../Styles/letUsKnow.module.css";
const Filler = () => {
  return (
    <>
      <div className={styles.team}>
        <img src={teamimg} alt="team" className={styles.team_img} />
        <p className={styles.team_info}>
          <span className={styles.team_header}>Together,</span>
          <br />
          <span className={styles.team_data}>
            we help empower your business to realize the transformative power of
            data.
          </span>
        </p>
      </div>
    </>
  );
};

export { Filler };
